import React, { useEffect, useState } from 'react';
import { Form, Select, Modal, Icon, Header } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton'
import { useParams, useHistory, Link } from 'react-router-dom'
import businessService from '../../services/businessService'
import { paths } from '../../config/routes'
import './BrokeragesDetail.scss'


const BrokeragesDetail = () => {
    const { brokerageId } = useParams()
    const history = useHistory()
    const [brokerage, setBrokerage] = useState({
        name: '',
        category: ''
    })
    const [brokerageCategories, setBrokerageCategories] = useState([])
    const [actionFinished, setActionFinished] = useState(null)
    const [actionFinishedMessage, setActionFinishedMessage] = useState('')
    useEffect(() => {
        businessService.getBrokerageCategories()
            .then(res => setBrokerageCategories(
                res.data.map(category => ({
                    key: category.id, text: category.name, value: category.id
                }))
            ))
    }, [])

    useEffect(() => {
        if (brokerageId) {
            businessService.getBrokerage(brokerageId)
                .then(res => setBrokerage(res.data))
        }
    }, [brokerageId])

    useEffect(() => {
        if (actionFinished === 'created') {
            setActionFinishedMessage({
                body: 'La correduría se ha creado con exito.'
            })
        } else if (actionFinished === 'updated') {
            setActionFinishedMessage('La correduría se ha modificado con exito.')
        } else if (actionFinished === 'deleted') {
            setActionFinishedMessage('La correduría se ha eliminado con exito.')
        } else if (actionFinished === 'error') {
            setActionFinishedMessage('Hubo un problema al eliminar la correduría. Intente nuevamente mas tarde.')
        }
    }, [actionFinished])

    const handleChange = (e, { name, value }) => {
        setBrokerage({ ...brokerage, [name]: value });
    };

    const createBrokerage = async () => {
        try {
            await businessService.createBrokerage(brokerage)
            setActionFinished('created')
        }
        catch (e) {
            setActionFinished('error')
        }
    }

    const updateBrokerage = async () => {
        try {
            await businessService.updateBrokerage(brokerageId, brokerage)
            setActionFinished('updated')
        }
        catch (e) {
            setActionFinished('error')
        }
    }

    const deleteBrokerage = async () => {
        try {
            await businessService.deleteBrokerage(brokerageId)
            setActionFinished('deleted')
        }
        catch (e) {
            setActionFinished('error')
        }
    }

    return (
        <>
            <Header className='cb-page-title'>
                Detalle de la correduría
                <Header.Subheader>
                    Puedes modificar los datos o eliminarla
                </Header.Subheader>
            </Header>
            <div className='cb-brokerage-wrapper'>

                {actionFinished && <Modal open={() => { }}
                    size='mini'
                    dimmer='blurring'>
                    <Modal.Header>
                        <Icon color={actionFinished === 'error' ? 'red' : 'green'} name={actionFinished === 'error' ? 'x' : 'check circle'} />
                        Operacion Finalizada
                    </Modal.Header>
                    <Modal.Content>
                        {actionFinishedMessage}
                    </Modal.Content>
                    <Modal.Actions>
                        <Link to={paths.BROKERAGES}>
                            <PrimaryButton compact>
                                Aceptar
                            </PrimaryButton>
                        </Link>
                    </Modal.Actions>
                </Modal>}

                <Form>
                    <Form.Field>
                        <label>Nombre</label>
                        <Form.Input
                            placeholder='Nombre'
                            name='name'
                            value={brokerage.name}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>CIF</label>
                        <Form.Input
                            placeholder='CIF'
                            name='tax_id'
                            value={brokerage.tax_id}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Categoría</label>
                        <Select
                            placeholder='Selecciona una categoría'
                            name='category_id'
                            options={brokerageCategories}
                            value={brokerage.category_id}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <PrimaryButton type='button'
                        onClick={brokerageId === 'create' ? createBrokerage : updateBrokerage}>
                        Guardar
                    </PrimaryButton>
                    <PrimaryButton type='button'
                        onClick={deleteBrokerage}>
                        Borrar
                    </PrimaryButton>
                </Form>
            </div>
        </>
    );
};

export default BrokeragesDetail 
