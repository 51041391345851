import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'

import { AccessRequestSegment, LoginSegment } from '../../components/Login'
import { PasswordResetSegment } from '../../components/PasswordReset'

import loginBackground from '../../assets/images/login/login-background.jpg'
import './Login.scss'

const Login = () => {
    const bodyStyle = `
        body {
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.4)),
                url(${loginBackground});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    `

    // Segments visibility
    const [isLoginVisible, setLoginVisibility] = useState(true)
    const [isAccessRequestVisible, setAccessRequestVisibility] = useState(false)
    const [isPasswordResetVisible, setPasswordResetVisibility] = useState(false)

    const showAccessRequest = () => {
        setLoginVisibility(false)
        setAccessRequestVisibility(true)
    }

    const showPasswordReset = () => {
        setLoginVisibility(false)
        setPasswordResetVisibility(true)
    }

    const showLogin = () => {
        setAccessRequestVisibility(false)
        setPasswordResetVisibility(false)
        setLoginVisibility(true)
    }

    return (
        <>
            <Helmet>
                <title>Login - CenterRisk</title>
                <style>
                    {bodyStyle}
                </style>
            </Helmet>
            <div className='cb-login-segment-wrapper'>
                {isLoginVisible &&
                    <LoginSegment
                        showAccessRequest={showAccessRequest}
                        showPasswordReset={showPasswordReset}/>}

                {isAccessRequestVisible &&
                    <AccessRequestSegment
                        showLogin={showLogin}/>}

                {isPasswordResetVisible &&
                    <PasswordResetSegment
                        showLogin={showLogin}/>}
            </div>
        </>
    )
}

export default Login