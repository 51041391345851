import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Button, Divider, Icon, Image, Message } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'
import { paths } from '../../../config/routes'
import { quoteStatus, quoteStatusNames } from '../../../assets/constants/quote'

import QuotesStatisticsForm from '../QuotesStatisticsForm/QuotesStatisticsForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'

import accountsService from '../../../services/accountsService'
import businessService from '../../../services/businessService'
import quotesService from '../../../services/quotesService'

import './QuotesStatisticsPanel.scss'
import { useUserGroups, useAuthState } from '../../../contexts/Auth'


const QuotesStatisticsPanel = (props) => {
    const userGroups = useUserGroups()
    const loggedUser = useAuthState()
    const [loadingStatus, setLoadingStatus] = useState(null)
    const [formOptions, setFormOptions] = useState({})
    const [requestSuccess, setRequestSuccess] = useState(false)
    const { setErrorDialog } = useErrorState()
    const history = useHistory()

    const generateCompaniesOptions = companies => {
        const options = []

        companies.forEach(company => {
            options.push({
                key: company.id,
                text: company.name,
                value: company.id
            })
        })

        return options
    }

    const generateBrokeragesOptions = brokerages => {
        const options = []

        brokerages.forEach(brokerage => {
            options.push({
                key: brokerage.id,
                text: brokerage.name,
                value: brokerage.id
            })
        })

        return options
    }

    const generateModalitiesOptions = modalities => {
        const options = []

        modalities.forEach(modality => {
            options.push({
                key: modality.code,
                text: modality.long_name,
                value: modality.code,
                type_id: modality.type_id
            })
        })

        return options
    }

    const generateStatusesOptions = () => {
        const closedStatuses = quoteStatus.closed()
        const statusesList = [quoteStatus.IN_PROGRESS, ...closedStatuses]

        const options = []

        statusesList.forEach(statusCode => {
            let statusName = quoteStatusNames[statusCode].singular
            statusName = `${closedStatuses.includes(statusCode) ? `Cerrada (${statusName.toLowerCase()})` : statusName}`

            options.push({
                key: statusCode,
                text: statusName,
                value: statusCode
            })
        })

        return options
    }

    const generateTechniciansOptions = technicians => {
        const options = []

        technicians.forEach(technician => {
            options.push({
                key: technician.id,
                text: `${technician.first_name} ${technician.last_name}`,
                value: technician.id
            })
        })

        return options
    }

    const generateBrokerageUsersOptions = users => {

        const options = []

        users.forEach(user => {
            options.push({
                key: user.id,
                text: `${user.first_name} ${user.last_name}`,
                value: user.id
            })

        })

        return options
    }

    useEffect(() => {

        setLoadingStatus({
            isLoading: true,
            message: 'Cargando datos de formulario...'
        })

        const options = {
            statuses: generateStatusesOptions()
        }

        businessService.getBrokerages()
            .then(res => {
                options.brokerages = generateBrokeragesOptions(res.data)
                return businessService.getCompanies()
            })
            .then(res => {
                options.companies = generateCompaniesOptions(res.data)
                return quotesService.getModalities()
            })
            .then(res => {
                options.modalities = generateModalitiesOptions(res.data)
                return accountsService.getTechnicians()
            })
            .then(res => {
                options.technicians = generateTechniciansOptions(res.data)
                if (loggedUser.loggedUser.brokerage) {
                    return accountsService.getBrokerageUsers(loggedUser.loggedUser.brokerage.id)
                } else {
                    setFormOptions(options)
                    setLoadingStatus({ isLoading: false })
                    return false
                }
            })
            .then(res => {
                if (res) {
                    options.users = generateBrokerageUsersOptions(res.data.users)
                    setFormOptions(options)
                    setLoadingStatus({ isLoading: false })
                }

            })
            .catch(() => {
                setLoadingStatus({ isLoading: false })

                setErrorDialog({
                    title: 'Error al cargar datos',
                    description: `Ha tenido lugar un error al cargar los datos del formulario.
                                  Por favor, inténtalo de nuevo más tarde o contacta con el
                                  administrador de la plataforma.`,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }, [])

    return (
        <>
            {
                (loadingStatus && !loadingStatus.isLoading) &&
                <div className='cb-quote-statistics-panel'>
                    <QuotesStatisticsForm options={formOptions} />
                    <Divider hidden />
                    <Divider />
                    <Divider hidden />
                    <PrimaryButton
                        icon
                        labelPosition='right'
                        form='cb-quote-statistics-form'
                        size='large'
                        className='cb-generate-document-button'>
                        Generar informe
                        <Icon name='file excel outline' />
                    </PrimaryButton>
                </div>
            }
            {
                loadingStatus?.isLoading &&
                <FullPageLoader text={loadingStatus?.message || 'Cargando...'} />
            }
        </>
    )
}

export default QuotesStatisticsPanel