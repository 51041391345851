import React from 'react'

import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { AccessRequestsTable } from '../../components/AccessRequests'

import './AccessRequests.scss'

const AccessRequests = () => {
    return (
        <>
            <Helmet>
                <title>Solicitudes de acceso - CenterRisk</title>
            </Helmet>
            <div className='cb-access-requests-wrapper'>
                <Header className='cb-page-title'>
                    Administra las solicitudes de acceso pendientes de tramitar
                </Header>
                <AccessRequestsTable/>
            </div>
        </>
    )
}

export default AccessRequests 
