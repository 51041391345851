import React, { useState } from 'react'

import { Form, Icon, Message, Modal } from 'semantic-ui-react'

import { logout, useAuthDispatch, useAuthState } from '../../../contexts/Auth'

import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import userIcon from '../../../assets/images/common/user-icon.png'
import './UserInfoWidget.scss'

const UserInfoWidget = (props) => {
    const { loggedUser } = useAuthState()
    const dispatch = useAuthDispatch()

    const [logoutConfirmationVisible, setLogoutConfirmationVisible] = useState(false)
    const [passwordChangeVisible, setPasswordChangeVisible] = useState(false)
    const [optionsVisible, setOptionsVisible] = useState(false)
    const [isPasswordChangeLoading, setPasswordChangeLoading] = useState(false)
    const [isPasswordChangeSuccessful, setPasswordChangeSuccessful] = useState(false)

    const completePasswordChange = () => {
        setPasswordChangeVisible(false)
        setPasswordChangeSuccessful(false)
    }

    return (
        <>
            <div className='cb-user-info-widget'
                 onMouseEnter={() => setOptionsVisible(true)}
                 onMouseLeave={() => setOptionsVisible(false)}>
                <div className='cb-main-content'>
                    <div className='cb-user-data'>
                        <span className='cb-user-name'>
                            {`${loggedUser.first_name} ${loggedUser.last_name}`}
                        </span>
                        {
                            loggedUser.brokerage &&
                            <span className='cb-user-brokerage'>
                                {`${loggedUser.brokerage.name}`}
                            </span>
                        }
                    </div>
                    <img src={userIcon} alt='user-icon'/>
                </div>
                {
                    optionsVisible &&
                    <div className='cb-user-options'>
                        <div className='cb-user-option'
                             onClick={() => setPasswordChangeVisible(true)}>
                            Cambiar contraseña
                        </div>
                        <div className='cb-user-option'
                             onClick={() => setLogoutConfirmationVisible(true)}>
                            Cerrar sesión
                        </div>
                    </div>
                }
            </div>
            <Modal open={isPasswordChangeSuccessful}
                   closeIcon={true}
                   onClose={completePasswordChange}
                   size='mini'
                   dimmer='blurring'>
                <Modal.Header>
                    <Icon color='green' name='check circle'/>
                    Contraseña cambiada
                </Modal.Header>
                <Modal.Content>
                    La contraseña ha sido cambiada correctamente
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton compact onClick={completePasswordChange}>
                        Aceptar
                    </PrimaryButton>
                </Modal.Actions>
            </Modal>
            <Modal
                open={logoutConfirmationVisible}
                closeIcon={true}
                onClose={() => setLogoutConfirmationVisible(false)}
                size='mini'
                dimmer='blurring'
                header='Cerrar sesión'
                content='¿Seguro que quieres cerrar la sesión?'
                actions={[{
                  key: 'deny',
                  content: 'Cancelar',
                  compact: true,
                  as: PrimaryButton
                }, {
                  key: 'accept',
                  content: 'Aceptar',
                  compact: true,
                  as: SecondaryButton,
                  onClick: () => logout(dispatch)
                }]}
            />
            <Modal open={passwordChangeVisible && !isPasswordChangeSuccessful}
                   size='tiny'
                   dimmer='blurring'>
                <Modal.Header>Cambiar contraseña</Modal.Header>
                <Modal.Content>
                    <ChangePasswordForm
                        setPasswordChangeLoading={setPasswordChangeLoading}
                        setPasswordChangeSuccessful={setPasswordChangeSuccessful}/>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton compact onClick={() => setPasswordChangeVisible(false)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton compact form='cb-change-password-form' type='submit' loading={isPasswordChangeLoading} disabled={isPasswordChangeLoading}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default UserInfoWidget