import React, { useContext } from 'react'
import { AuthDispatchContext, AuthStateContext } from './context'

import { authGroupsList } from '../../assets/constants/auth'

export function useAuthState() {
    const context = useContext(AuthStateContext)
    
    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider')
    }

    return context
}
 
export function useAuthDispatch() {
    const context = useContext(AuthDispatchContext)
    
    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider')
    }

    return context
}

export function useUserGroups() {
    const { loggedUser } = useAuthState()
    const check = {}

    if (!loggedUser) {
        return check
    }

    const groups = loggedUser.groups

    authGroupsList.forEach(authGroup => {
        check[authGroup] = groups.includes(authGroup)
    })

    return check
}

export function useUser() {
    const { loggedUser } = useAuthState()
    return loggedUser
}

export function useUserInGroups(groupList) {
    if (!groupList) {
        return true
    }

    const { loggedUser } = useAuthState()

    if (!loggedUser) {
        return false
    }
    
    return loggedUser.groups.some(group => groupList.indexOf(group) >= 0)
}