import React, { useState } from 'react'
import { Button, Divider, Image, Message, Segment } from 'semantic-ui-react'

import AccessRequestForm from '../AccessRequestForm/AccessRequestForm'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import centerriskLogo from '../../../assets/images/common/centerrisk-logo.png'
import './AccessRequestSegment.scss'

const AccessRequestSegment = (props) => {
    const [loading, setLoading] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(false)

    return (
        <Segment className='cb-access-request-segment'>
            <Image src={centerriskLogo} size='medium' className='cb-centerrisk-logo' />
            {
                requestSuccess
                ? <Message positive icon='check' header='Acceso solicitado' 
                    content='La solicitud de acceso ha sido enviada correctamente.
                             El Departamento de Empresas se pondrá en contacto contigo
                             próximamente.'/>
                : <AccessRequestForm setLoading={setLoading}
                                     setRequestSuccess={setRequestSuccess}
                                     showLogin={props.showLogin}/>
            }
            <Divider/>
            {
                requestSuccess
                ? <SecondaryButton fluid className='cb-bottom-margin' onClick={props.showLogin}>
                      Aceptar
                  </SecondaryButton>
                : <Button.Group fluid className='cb-bottom-margin'>
                      <SecondaryButton onClick={props.showLogin}>
                          Cancelar
                      </SecondaryButton>
                      <PrimaryButton form='cb-access-request-form' type='submit' loading={loading} disabled={loading}>
                          Enviar solicitud
                      </PrimaryButton>
                  </Button.Group>
            }
        </Segment>
    )
}

export default AccessRequestSegment