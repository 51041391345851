import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import {
    Button, Divider, Dropdown, Form,
    Icon, Input, Label, Menu, Message,
    Modal, Table
} from 'semantic-ui-react'

import { paths } from '../../../config/routes'
import quotesService from '../../../services/quotesService'
import documentationService from '../../../services/documentationService'
import { useErrorState } from '../../../contexts/ErrorHandling'
import { quotesFilters, quoteStatus, quoteStatusNames } from '../../../assets/constants/quote'
import * as utils from '../../../assets/js/utils'

import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import InfoLabel from '../../UI/InfoLabel/InfoLabel'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'

import './BrokerageQuotesTable.scss'

const BrokerageQuotesTable = (props) => {
    const initialFilterConfig = {
        [quotesFilters.PAGE]: 1,
        [quotesFilters.PAGE_SIZE]: 10,
        [quotesFilters.SEARCH]: '',
        [quotesFilters.ORDER_BY]: '',
        [quotesFilters.DIRECTION]: ''
    }
    if (props.quoteType)
        initialFilterConfig.type_id = props.quoteType
    const [filterConfig, setFilterConfig] = useState({ ...initialFilterConfig })
    const [searchString, setSearchString] = useState('')
    const [tempSortingConfig, setTempSortingConfig] = useState(null)
    const [refreshAmount, setRefreshAmount] = useState(1)
    const [quotesData, setQuotesData] = useState(null)
    const [sortingFormErrors, setSortingFormErrors] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isSortingModalVisible, setSortingModalVisible] = useState(false)
    const { setErrorDialog } = useErrorState()
    const history = useHistory()

    const handleInput = (event, result) => {
        const { value } = result || event.target
        setSearchString(value)
    }

    const handleTempChange = (event, result, fieldName) => {
        const { value } = result || event.target
        setTempSortingConfig({
            ...tempSortingConfig,
            [fieldName]: value
        })
    }

    const handleChange = (event, result, fieldName) => {
        const { value } = result || event.target
        setFilterConfig({
            ...filterConfig,
            [fieldName]: value
        })
    }

    const handleClick = pageNumber => {
        setFilterConfig({
            ...filterConfig,
            [quotesFilters.PAGE]: pageNumber
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilterConfig({
                ...filterConfig,
                [quotesFilters.SEARCH]: searchString
            })
        }
    }

    const calculateTotalAmount = () => {
        let totalAmount = 0

        for (let key in quotesData.results.status_amounts) {
            totalAmount += quotesData.results.status_amounts[key]
        }

        return totalAmount
    }

    const generateInfoLabelContent = (status) => {
        let labelStart

        if ((status === quoteStatus.NEW) && props.isBrokerView) {
            labelStart = 'PENDIENTES DE VALIDAR'
        } else {
            const statusName = quoteStatusNames[status].plural.toUpperCase()
            labelStart = `COTIZACIONES ${statusName}`
        }

        const currentAmount = quotesData.results.status_amounts?.[status] || 0
        const totalAmount = calculateTotalAmount()

        return (
            <>
                {labelStart} <strong>{currentAmount}</strong>/{totalAmount}
            </>
        )
    }

    const generateDropdownOptions = () => {
        const options = []

        for (let i = 1; i <= 15; i++) {
            options.push({
                key: i,
                text: i,
                value: i,
                content: i,
            })
        }

        return options
    }

    const generateResultsNumbersInformation = (status) => {
        const pageSize = quotesData.pageSize
        const firstElementNumber = ((quotesData.currentPage - 1) * pageSize + 1)
        const lastElementNumber = ((firstElementNumber + quotesData.results.quotes.length) - 1)
        const totalAmount = quotesData.total

        return `${firstElementNumber}-${lastElementNumber} de ${totalAmount}`
    }

    const cancelSorting = () => {
        setSortingModalVisible(false)
        setTempSortingConfig(null)
        setSortingFormErrors([])
    }

    const applySorting = () => {
        const errors = []

        if (!tempSortingConfig?.[quotesFilters.ORDER_BY]) {
            errors.push(quotesFilters.ORDER_BY)
        }

        if (!tempSortingConfig?.[quotesFilters.DIRECTION]) {
            errors.push(quotesFilters.DIRECTION)
        }

        if (errors.length > 0) {
            setSortingFormErrors(errors)
            return
        }

        setSortingModalVisible(false)
        setFilterConfig({
            ...filterConfig,
            ...tempSortingConfig
        })
        setTempSortingConfig(null)
        setSortingFormErrors([])
    }

    const getBrokerageQuotes = (status, brokerageId) => {
        setLoading('listado')

        const params = {}

        for (let key in filterConfig) {
            const value = filterConfig[key]

            if (value) {
                params[key] = value
            }
        }
        quotesService.getBrokerageQuotes(brokerageId, status, params)
            .then(res => {
                setQuotesData(res.data)
                console.log('BrokerageQuotesTable',res.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                const currentPage = filterConfig[quotesFilters.PAGE]

                if ((error?.response?.status === 404) && (currentPage > 1)) {
                    setFilterConfig({
                        ...filterConfig,
                        [quotesFilters.PAGE]: 1
                    })
                    return
                }

                setLoading(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar las cotizaciones. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando cotizaciones',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    const downloadQuotePdf = quoteId => {
        setLoading('documento')

        documentationService.downloadQuotePdf(quoteId)
            .then(res => {
                const tempAnchor = document.createElement('a')

                tempAnchor.setAttribute('rel', 'noopener')
                tempAnchor.setAttribute('target', '_blank')
                tempAnchor.setAttribute('href', res.data.file_url)
                tempAnchor.style.display = 'none'

                document.body.appendChild(tempAnchor)
                tempAnchor.click()
                document.body.removeChild(tempAnchor)

                setLoading(false)
            })
            .catch(() => {
                setErrorDialog({
                    title: 'Error al descargar archivo',
                    description: `Ha tenido lugar un error al descargar el archivo.
                                  Por favor, inténtalo de nuevo más tarde o contacta con el
                                  administrador de la plataforma.`
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        setSearchString('')
        setTempSortingConfig(null)
        setFilterConfig({ ...initialFilterConfig })
        setRefreshAmount(refreshAmount + 1)
    }, [props.status, props.brokerage.id])

    useEffect(() => {
        getBrokerageQuotes(props.status, props.brokerage.id)
    }, [...Object.values(filterConfig), refreshAmount])

    return (
        <>
            {
                (isLoading || !quotesData) &&
                <FullPageLoader text={`Cargando ${isLoading}...`} />
            }
            {
                (!isLoading && quotesData) &&
                <div className='cb-quotes-table-wrapper'>
                    <div className='cb-quotes-table-header'>
                        <div className='cb-quotes-search-bar'>
                            <Input
                                autoFocus
                                icon='search'
                                iconPosition='left'
                                placeholder='Buscar...'
                                className='cb-search-input'
                                value={searchString}
                                onKeyDown={handleKeyDown}
                                onChange={handleInput} />
                            <Button
                                basic
                                icon
                                className='cb-sorting-button'
                                onClick={() => setSortingModalVisible(true)}>
                                <Icon name='sort amount down' />
                            </Button>
                        </div>
                        {
                            quoteStatus.closed().includes(props.status) &&
                            <div className='cb-closed-quotes-shortcut-menu'>
                                <Menu pointing secondary stackable>
                                    {
                                        quoteStatus.closed().map((status, index) => (
                                            <Menu.Item
                                                key={index}
                                                name={status}
                                                active={props.status === status}
                                                onClick={() => {
                                                    const path = props.isBrokerView
                                                        ? paths.BROKERAGE_QUOTES_BROKER
                                                        : paths.BROKERAGE_QUOTES_BUSINESS
                                                    history.push(
                                                        path.replace(':statusSlug',
                                                            props.quoteType === 2 ?
                                                                `health/${quoteStatus.toSlug(status)}` :
                                                                `${quoteStatus.toSlug(status)}`
                                                        )
                                                            .replace(':brokerageId', props.brokerage.id)
                                                    )
                                                }}>
                                                {quoteStatusNames[status].plural}
                                                <Label circular size='mini' className='cb-amount-label'>
                                                    {quotesData.results.status_amounts?.[status] || 0}
                                                </Label>
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu>
                            </div>
                        }
                    </div>
                    {
                        (quotesData.results?.quotes?.length > 0) &&
                        <>
                            <div className='cb-quotes-table'>
                                <Table stackable selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={4}>TOMADOR</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>NIF/CIF</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>
                                                FECHA DE {quoteStatus.closed().includes(props.status) ? 'CIERRE' : 'ENTRADA'}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={3}>Nº COTIZACIÓN</Table.HeaderCell>
                                            <Table.HeaderCell width={3}>MODALIDAD</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            quotesData?.results?.quotes?.map((quote, index) => (
                                                <Table.Row
                                                    key={index}
                                                    className='cb-quote'
                                                    onClick={
                                                        (props.status === quoteStatus.DRAFT)
                                                            ? () => history.push(paths.RESUME_QUOTE_REQUEST.replace(':quoteId', quote.id))
                                                            : ((props.status === quoteStatus.NEW) && props.isBrokerView)
                                                                ? () => downloadQuotePdf(quote.id)
                                                                : () => history.push(paths.QUOTE_MANAGEMENT.replace(':quoteId', quote.id))
                                                    }>
                                                    <Table.Cell>
                                                        <strong>{quote.data.policyholderName.textual || '-'}</strong>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {quote.data.policyholderTaxID.textual || '-'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {utils.datetimeToStr(quote.display_date)}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {quote.number || '-'}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Label
                                                            size='large'
                                                            content={quote.modality.short_name.toUpperCase()}
                                                            className='cb-modality-label' />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                            <div className='cb-quotes-page-management'>
                                <Table>
                                    <Table.Footer>
                                        <Table.Row>
                                            {
                                                (props.status !== quoteStatus.DRAFT) &&
                                                <Table.HeaderCell width={6}>
                                                    <InfoLabel>
                                                        {generateInfoLabelContent(props.status)}
                                                    </InfoLabel>
                                                </Table.HeaderCell>
                                            }
                                            <Table.HeaderCell width={(props.status !== quoteStatus.DRAFT) ? 5 : 11}>
                                                <strong>Resultados por página:</strong>
                                                <Dropdown
                                                    inline
                                                    scrolling
                                                    upward
                                                    options={generateDropdownOptions()}
                                                    name={quotesFilters.PAGE_SIZE}
                                                    value={filterConfig[quotesFilters.PAGE_SIZE]}
                                                    onChange={(event, result) => handleChange(event, result, quotesFilters.PAGE_SIZE)}
                                                    className='cb-page-size-dropdown' />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={2} className='cb-page-results-numbers'>
                                                <strong>{generateResultsNumbersInformation(props.status)}</strong>
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={3} className='cb-page-switch-buttons'>
                                                <Button
                                                    basic
                                                    compact
                                                    icon
                                                    disabled={quotesData.previousPage === null}
                                                    onClick={() => handleClick(quotesData.previousPage)}>
                                                    <Icon name='chevron left' />
                                                </Button>
                                                <Button
                                                    basic
                                                    compact
                                                    icon
                                                    disabled={quotesData.nextPage === null}
                                                    onClick={() => handleClick(quotesData.nextPage)}>
                                                    <Icon name='chevron right' />
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </>
                    }
                    {
                        (quotesData.results?.quotes?.length === 0) &&
                        <Message info icon size='large' className='cb-empty-message'>
                            <Icon name='info circle' />
                            <Message.Content>
                                <Message.Header>
                                    No se han encontrado cotizaciones
                                </Message.Header>
                                Actualmente no hay ninguna solicitud de cotización
                                <strong>
                                    <i> {quoteStatusNames[props.status].singular.toLowerCase()} </i>
                                </strong>
                                {
                                    !props.isBrokerView &&
                                    <>
                                        por parte de la correduría
                                        <strong> {props.brokerage.name} </strong>
                                    </>
                                }
                                {
                                    (filterConfig[quotesFilters.SEARCH] !== '') &&
                                    <>
                                        que cumpla con los criterios de búsqueda
                                    </>
                                }
                            </Message.Content>
                        </Message>
                    }
                </div>
            }
            <Modal open={isSortingModalVisible}
                size='tiny'
                dimmer='blurring'
                className='cb-sorting-modal'>
                <Modal.Header>
                    Ordenar listado
                </Modal.Header>
                <Modal.Content>
                    <Form error={sortingFormErrors.length > 0}>
                        <Form.Dropdown
                            selection
                            fluid
                            error={sortingFormErrors.includes(quotesFilters.ORDER_BY)}
                            label='Ordenar por'
                            placeholder='Seleccionar'
                            name={quotesFilters.ORDER_BY}
                            value={tempSortingConfig?.[quotesFilters.ORDER_BY] || ''}
                            onChange={(event, result) => handleTempChange(event, result, quotesFilters.ORDER_BY)}
                            options={[
                                {
                                    key: 'policyholderName',
                                    text: 'Tomador',
                                    value: 'policyholderName'
                                },
                                {
                                    key: 'policyholderTaxID',
                                    text: 'NIF/CIF',
                                    value: 'policyholderTaxID'
                                },
                                {
                                    key: 'date',
                                    text: 'Fecha',
                                    value: 'date'
                                },
                                {
                                    key: 'modality',
                                    text: 'Modalidad',
                                    value: 'modality'
                                }
                            ]} />
                        <Form.Dropdown
                            selection
                            fluid
                            error={sortingFormErrors.includes(quotesFilters.DIRECTION)}
                            label='Sentido del orden'
                            placeholder='Seleccionar'
                            name={quotesFilters.DIRECTION}
                            value={tempSortingConfig?.[quotesFilters.DIRECTION] || ''}
                            onChange={(event, result) => handleTempChange(event, result, quotesFilters.DIRECTION)}
                            options={[
                                {
                                    key: 'ascending',
                                    text: 'Ascendente',
                                    value: 'ascending'
                                },
                                {
                                    key: 'descending',
                                    text: 'Descendente',
                                    value: 'descending'
                                }
                            ]} />
                        {
                            (sortingFormErrors.length > 0) &&
                            <Message error>
                                <Message.List>
                                    <Message.Item>
                                        Es obligatorio indicar todos los campos
                                    </Message.Item>
                                </Message.List>
                            </Message>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        onClick={cancelSorting}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        onClick={applySorting}>
                        Aceptar
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default BrokerageQuotesTable