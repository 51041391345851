import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Form, Header, Icon, Input } from 'semantic-ui-react'

import { quotesFilters, quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import * as utils from '../../assets/js/utils'
import { paths } from '../../config/routes'
import { useErrorState } from '../../contexts/ErrorHandling'

import { QuotesStatisticsPanel } from '../../components/QuotesStatistics'
import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'

import quotesService from '../../services/quotesService'

import './QuotesStatistics.scss'

const QuotesStatistics = () => {
    const [isLoading, setLoading] = useState(false)

    return (
        <>
            <Helmet>
                <title>
                    Estadísticas de cotizaciones - CenterRisk
                </title>
            </Helmet>
            <div className='cb-quotes-statistics-wrapper'>
                <Header className='cb-page-title'>
                    Generación de estadísticas de cotizaciones
                    <Header.Subheader>
                        Configura los filtros y genera un documento con estadísticas de cotizaciones
                    </Header.Subheader>
                </Header>
                <QuotesStatisticsPanel/>
                {
                    isLoading &&
                    <FullPageLoader text='Generando documento...'/>
                }
            </div>
        </>
    )
}

export default QuotesStatistics 
