import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Header, Modal } from "semantic-ui-react";
import Clock from "react-live-clock";

import {
  chartColor,
  quoteStatus,
  quoteStatusHints,
} from "../../assets/constants/quote";
import { paths } from "../../config/routes";

import { useUserGroups, useUser } from "../../contexts/Auth";
import {
  useClosedQuotesAmount,
  useQuotesAmountByStatus,
  useHealthQuotesAmountByStatus,
  useHealthClosedQuotesAmount,
} from "../../contexts/PeriodicUpdates";

import {
  QuotesAmountStat,
  QuotesAmountsChart,
  QuotesBasicOverviewTable,
} from "../../components/Dashboard";

import ShortcutCard from "../../components/UI/ShortcutCard/ShortcutCard";

import "./Dashboard.scss";

const STANDARD_DASHBOARD = 1;
const HEALTH_DASHBOARD = 2;

const Dashboard = () => {
  const [isHealthDashboard, setIsHealthDashboard] = useState(
    parseInt(localStorage.getItem("activeDashboardIndex"), 10) === 1
  );
  const newQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.NEW)
    : useQuotesAmountByStatus(quoteStatus.NEW);
  const inProgressQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.IN_PROGRESS)
    : useQuotesAmountByStatus(quoteStatus.IN_PROGRESS);
  const closedQuotesAmount = isHealthDashboard
    ? useHealthClosedQuotesAmount()
    : useClosedQuotesAmount();
  const issuedQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.ISSUED)
    : useQuotesAmountByStatus(quoteStatus.ISSUED);
  const quotedQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.QUOTED)
    : useQuotesAmountByStatus(quoteStatus.QUOTED);
  const rejectedQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.REJECTED)
    : useQuotesAmountByStatus(quoteStatus.REJECTED);
  const notAcceptedQuotesAmount = isHealthDashboard
    ? useHealthQuotesAmountByStatus(quoteStatus.NOT_ACCEPTED)
    : useQuotesAmountByStatus(quoteStatus.NOT_ACCEPTED);
  const userGroups = useUserGroups();
  const user = useUser();
  const [
    closedQuotesBreakdownModalVisible,
    setClosedQuotesBreakdownModalVisible,
  ] = useState(false);
  const isStaff = userGroups.business_department || userGroups.technical;

  useEffect(() => {
    const handleDashboardIndexChange = (event) => {
      setIsHealthDashboard(
        parseInt(localStorage.getItem("activeDashboardIndex"), 10)
      );
    };
    window.addEventListener(
      "dashboardIndexChanged",
      handleDashboardIndexChange
    );
    return () => {
      window.removeEventListener(
        "dashboardIndexChanged",
        handleDashboardIndexChange
      );
    };
  }, []);

  const newStatusLabel = () => (isStaff ? "Nuevas" : "Pendientes de validar");

  const generateLink = (isHealthDashboard, isStaff, statusSlug) => {
    const basePath = isStaff
      ? paths.QUOTES_PER_BROKERAGE
      : paths.BROKERAGE_QUOTES_BROKER;
    return basePath.replace(
      ":statusSlug",
      isHealthDashboard ? `health/${statusSlug}` : statusSlug
    );
  };

  return (
    <>
      <Helmet>
        <title>Inicio - CenterRisk</title>
      </Helmet>
      <div className="cb-dashboard-wrapper">
        <Header className="cb-page-title">
          Bienvenido/a, estos son los datos más actualizados
          <Header.Subheader>
            Hoy, día{" "}
            <Clock
              format={"DD/MM/YYYY - HH:mm:ss"}
              ticking={true}
              timezone={"Europe/Madrid"}
            />
          </Header.Subheader>
        </Header>
        <section className="cb-overall-data-section">
          <QuotesAmountsChart
            labels={[` ${newStatusLabel()}`, " En trámite", " Cerradas"]}
            colors={[chartColor.NEW, chartColor.IN_PROGRESS, chartColor.CLOSED]}
            amounts={[
              newQuotesAmount,
              inProgressQuotesAmount,
              closedQuotesAmount,
            ]}
          />
          <div className="cb-quotes-stats-wrapper">
            <QuotesAmountStat
              title={newStatusLabel()}
              amount={newQuotesAmount}
              color={chartColor.NEW}
              link={generateLink(isHealthDashboard, isStaff, "new")}
              className="cb-new"
            />
            <QuotesAmountStat
              title="En trámite"
              amount={inProgressQuotesAmount}
              color={chartColor.IN_PROGRESS}
              link={generateLink(isHealthDashboard, isStaff, "in-progress")}
              hint={
                userGroups.brokers ? quoteStatusHints.inProgress : undefined
              }
              className="cb-in-progress"
            />
            <QuotesAmountStat
              title="Cerradas"
              amount={closedQuotesAmount}
              color={chartColor.CLOSED}
              className="cb-closed"
              hint={userGroups.brokers ? quoteStatusHints.closed : undefined}
              onClick={() => setClosedQuotesBreakdownModalVisible(true)}
            />
          </div>
        </section>
        {isStaff && (
          <section className="cb-overview-tables-section">
            <QuotesBasicOverviewTable
              title="Cotizaciones sin asignar"
              statusRange={quoteStatus.ranges.NEW}
              quoteType={
                isHealthDashboard ? HEALTH_DASHBOARD : STANDARD_DASHBOARD
              }
            />
            <QuotesBasicOverviewTable
              title="Cotizaciones cerradas totales"
              statusRange={quoteStatus.ranges.CLOSED}
              quoteType={
                isHealthDashboard ? HEALTH_DASHBOARD : STANDARD_DASHBOARD
              }
            />
          </section>
        )}
        {userGroups.brokers && (
          <section className="cb-shortcuts-section">
            <ShortcutCard
              title="Solicitar Cotización Empresas"
              link={paths.QUOTE_REQUEST}
            />
            <ShortcutCard
              title="Solicitudes guardadas"
              link={paths.BROKERAGE_QUOTES_BROKER.replace(
                ":statusSlug",
                "draft"
              )}
            />
            <ShortcutCard
              title="Emisión Salud Colectivo C.B."
              link={paths.QUOTE_HEALTH_REQUEST}
            />
          </section>
        )}
      </div>
      <Modal
        basic
        open={closedQuotesBreakdownModalVisible}
        dimmer="blurring"
        closeIcon={true}
        onClose={() => setClosedQuotesBreakdownModalVisible(false)}
        className="cb-closed-quotes-amounts-breakdown-modal"
      >
        <Modal.Content>
          <QuotesAmountStat
            title="Emitidas"
            amount={issuedQuotesAmount}
            color={chartColor.CLOSED}
            link={generateLink(isHealthDashboard, isStaff, "issued")}
            className="cb-closed"
          />
          {!isHealthDashboard && (
            <QuotesAmountStat
              title="Cotizadas"
              amount={quotedQuotesAmount}
              color={chartColor.CLOSED}
              link={generateLink(isHealthDashboard, isStaff, "quoted")}
              className="cb-closed"
            />
          )}
          <QuotesAmountStat
            title="Rechazadas"
            amount={rejectedQuotesAmount}
            color={chartColor.CLOSED}
            link={generateLink(isHealthDashboard, isStaff, "rejected")}
            className="cb-closed"
          />
          <QuotesAmountStat
            title="No aceptadas"
            amount={notAcceptedQuotesAmount}
            color={chartColor.CLOSED}
            link={generateLink(isHealthDashboard, isStaff, "not-accepted")}
            className="cb-closed"
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Dashboard;
