export const isEmptyValue = (value) => {
    if (value === null || value === undefined) {
        return true
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0
    }

    if (typeof value === 'string') {
        return value.trim() === ''
    }

    if (typeof value === 'boolean') {
        return !value
    }

    return false
}

export const getFileIconProps = (fileName) => {
    const iconProps = {
        name: 'file alternate outline'
    }

    if (fileName.match(/.(doc|docx)$/i)) {
        iconProps.name = 'file word outline'
        iconProps.color = 'blue'
    } else if (fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
        iconProps.name = 'file image outline'
        iconProps.color = 'green'
    } else if (fileName.match(/.(pdf)$/i)) {
        iconProps.name = 'file pdf outline'
        iconProps.color = 'red'
    } else if (fileName.match(/.(xlsx|xlsm|xlsb|xltx|xltm|xls|xlt|xls|xml|xml|xlam|xla|xlw|xlr)$/i)) {
        iconProps.name = 'file excel outline'
        iconProps.className = 'cb-excel-green'
    } else if (fileName.match(/.(gz|bz2|rar|zip|7z)$/i)) {
        iconProps.name = 'file archive outline'
        iconProps.color = 'brown'
    } else if (fileName.match(/.(pst|msg|edb|ost|eml|mbox)$/i)) {
        iconProps.name = 'envelope outline'
    } else if (fileName.match(/\//i)) {
        iconProps.name = 'folder outline'
    }

    return iconProps
}

export const dateToStr = (date) => {
    if (!date) {
        return
    }

    if (typeof date === 'string') {
        date = new Date(date)
    }

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    day = day.toString().padStart(2, '0')
    month = month.toString().padStart(2, '0')

    return `${day}/${month}/${year}`
}

export const datetimeToStr = (datetime) => {
    if (!datetime) {
        return
    }

    if (typeof datetime === 'string') {
        datetime = new Date(datetime)
    }

    let day = datetime.getDate()
    let month = datetime.getMonth() + 1
    let year = datetime.getFullYear()
    let hours = datetime.getHours()
    let minutes = datetime.getMinutes()
    let seconds = datetime.getSeconds()

    day = day.toString().padStart(2, '0')
    month = month.toString().padStart(2, '0')
    hours = hours.toString().padStart(2, '0')
    minutes = minutes.toString().padStart(2, '0')
    seconds = seconds.toString().padStart(2, '0')

    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
}