import { axiosInstance } from './serviceConfig'

const businessEndpoint = '/api/business'

const getBrokerages = () => (
    axiosInstance.get(`${businessEndpoint}/brokerages`)
)

const getBrokerage = brokerageId => {
    return axiosInstance.get(`${businessEndpoint}/brokerages/${brokerageId}`)
}

const createBrokerage = async data => (
    await axiosInstance.post(`${businessEndpoint}/brokerages`, data)
)

const updateBrokerage = async(brokerageId, data) => (
    await axiosInstance.put(`${businessEndpoint}/brokerages/${brokerageId}`, data)
)

const deleteBrokerage = async brokerageId => (
    await axiosInstance.delete(`${businessEndpoint}/brokerages/${brokerageId}`)
)

const getBrokerageCategories = () => (
    axiosInstance.get(`${businessEndpoint}/brokerages/categories`)
)

const getCompanies = () => (
    axiosInstance.get(`${businessEndpoint}/companies`)
)

const getCompany = companyId => (
    axiosInstance.get(`${businessEndpoint}/companies/${companyId}`)
)

export default {
    getBrokerage,
    getBrokerages,
    createBrokerage,
    updateBrokerage,
    deleteBrokerage,
    getBrokerageCategories,
    getCompanies,
    getCompany
}