import React, { useEffect, useState } from 'react'

import { Form, Header } from 'semantic-ui-react'

import { sectionContentType } from '../../../../assets/constants/form'

import { CheckboxField } from '../'
import * as fieldTypes from '../'

import {
    deleteReferences,
    updateValue,
    updateVisibility,
    useEnablement,
    useValue,
    useVisibility,
    useFormDispatch,
    useFormState
} from '../../../../contexts/Form'

import InfoTooltip from '../../InfoTooltip/InfoTooltip'

import './Section.scss'


const Section = (props) => {
    const dispatch = useFormDispatch()
    const formState = useFormState()
    const isSectionChecked = useValue(props.name)
    const isSectionVisible = useVisibility(props.name)

    const isContentVisible = () => {
        if (props.checkable) {
            return isSectionChecked?.raw
        }
        
        return true
    }

    const generateHeader = () => {
        return (
            props.title?.trim() &&
            <Header className='cb-form-section-header'>
                {
                    props.checkable &&
                    <CheckboxField
                        name={props.name}
                        defaultValue={props.defaultChecked}/>
                }
                {props.title}
                {
                    props.info &&
                    <InfoTooltip text={props.info}/>
                }
            </Header>
        )
    }

    const generateContents = () => {
        const contents = []
        props.contents.forEach((content, index) => (
            contents.push(generateContent(content, index))
        ))

        return contents
    }

    const generateFieldsGroup = (content, index) => {
        const fields = []
        const className = content.withTopMargin ? `cb-top-margin-${content.withTopMargin}` : undefined

        content.data.map((field, fieldIndex) => (
            fields.push(generateSingleField(field, fieldIndex))
        ))

        return (
            !fields.every(v => !Boolean(v))
            ? <Form.Group widths={content.widths} className={className} key={index}>
                  {fields}
              </Form.Group>
            : null
        )
    }

    const generateSingleField = (field, index) => {
        const FieldComponent = fieldTypes[field.fieldType]

        return (
            (!formState.hiddenFields.includes(field.data.name) && (formState.visibilities[field.data.name] !== false))
            ? <FieldComponent
                  {...field.data}
                  key={index}/>
            : null
        )
    }

    const generateSubsection = (content, index) => (
        (formState.visibilities[content.data.name] !== false)
        ? <Section
              key={index}
              className={index === 0 && !content.withTopMargin ? 'cb-marginless-top' : null}
              {...content.data}/>
        : null
    )

    const generateContent = (content, index) => {
        switch (content.type) {
            case sectionContentType.FIELDS_GROUP:
                return generateFieldsGroup(content, index)
            case sectionContentType.SINGLE_FIELD:
                return generateSingleField(content.data, index)
            case sectionContentType.SUBSECTION:
                return generateSubsection(content, index)
        }
    }

    useEffect(() => {
        if (isSectionVisible === undefined) {
            updateVisibility(dispatch, props.name, props.defaultVisible !== false)
        }
    }, [])

    return (
        <section
            className={
                `${props.className ? `${props.className} `: ''}cb-level-${props.level} cb-form-section${props.checkable ? ' cb-checkable-section' : ''}`
            }>
            {generateHeader()}
            {
                isContentVisible() &&
                generateContents()
            }
        </section>
    )
}

export default Section