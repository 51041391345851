export const chartColor = {
    NEW: '#BAC3F5',
    IN_PROGRESS: '#E3BE5F',
    CLOSED: '#D17560'
}

export const requestStep = {
    MODALITY: 1,
    FORM: 3,
    CONFIRMATION: 2
}

export const quoteStatus = {
    DRAFT: 'DRAFT',
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    ISSUED: 'ISSUED',
    QUOTED: 'QUOTED',
    REJECTED: 'REJECTED',
    NOT_ACCEPTED: 'NOT_ACCEPTED',
    ranges: {
        NEW: 'new',
        CLOSED: 'closed'
    },
    all(includeDraft = false) {
        const list = [
            this.NEW, this.IN_PROGRESS, this.ISSUED,
            this.QUOTED, this.REJECTED, this.NOT_ACCEPTED
        ]

        if (includeDraft) {
            list.unshift(this.DRAFT)
        }

        return list
    },
    closed() {
        return [this.ISSUED, this.QUOTED, this.REJECTED, this.NOT_ACCEPTED]
    },
    fromSlug(slug) {
        switch (slug) {
            case 'draft':
                return this.DRAFT
            case 'new':
                return this.NEW
            case 'in-progress':
                return this.IN_PROGRESS
            case 'issued':
                return this.ISSUED
            case 'quoted':
                return this.QUOTED
            case 'rejected':
                return this.REJECTED
            case 'not-accepted':
                return this.NOT_ACCEPTED
        }
        
        return null
    },
    toSlug(status) {
        switch (status) {
            case this.DRAFT:
                return 'draft'
            case this.NEW:
                return 'new'
            case this.IN_PROGRESS:
                return 'in-progress'
            case this.ISSUED:
                return 'issued'
            case this.QUOTED:
                return 'quoted'
            case this.REJECTED:
                return 'rejected'
            case this.NOT_ACCEPTED:
                return 'not-accepted'
        }
        
        return null
    }
}

export const quoteStatusNames = {
    [quoteStatus.DRAFT]: {
        singular: 'Guardada',
        plural: 'Guardadas',
    },
    [quoteStatus.NEW]: {
        singular: 'Nueva',
        plural: 'Nuevas',
    },
    [quoteStatus.IN_PROGRESS]: {
        singular: 'En trámite',
        plural: 'En trámite',
    },
    [quoteStatus.ISSUED]: {
        singular: 'Emitida',
        plural: 'Emitidas',
    },
    [quoteStatus.QUOTED]: {
        singular: 'Cotizada',
        plural: 'Cotizadas',
    },
    [quoteStatus.REJECTED]: {
        singular: 'Rechazada',
        plural: 'Rechazadas',
    },
    [quoteStatus.NOT_ACCEPTED]: {
        singular: 'No aceptada',
        plural: 'No aceptadas',
    }
}

export const quotesFilters = {
    PAGE: 'page',
    PAGE_SIZE: 'page_size',
    SEARCH: 'search',
    ORDER_BY: 'order_by',
    DIRECTION: 'direction'
}

export const quoteStatusHints = {
    inProgress: `Aquellas que están en trámite y no han sido cotizadas
                 o rechazadas por el Departamento de Empresas`,
    closed: `Aquellas que han sido cotizadas por el Departamento de Empresas,
             se han convertido en póliza, desistidas por el corredor o Rechazadas
             por la cia. En las cotizaciones cerradas creamos cuatro subestados
             (emitidas, cotizadas, rechazadas, no aceptadas)`
}

export const quotePdfFilename = 'Datos de solicitud.pdf'