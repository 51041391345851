import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Header } from 'semantic-ui-react'

import { paths } from '../../config/routes'
import { useHistory } from 'react-router-dom'

import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'
import OverviewGrid from '../../components/UI/OverviewGrid/OverviewGrid'
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton'

import businessService from '../../services/businessService'

import './Brokerages.scss'


const Brokerages = () => {
    const [brokerages, setBrokerages] = useState(null)
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        businessService.getBrokerages()
            .then(res => {
                const brokerages = []
                res.data?.forEach(brokerage => {
                    const item = {
                        title: brokerage.name,
                        // content: brokerage.tax_id,
                        link: paths.BROKERAGES_DETAIL.replace(':brokerageId', brokerage.id)
                    }
                    brokerages.push(item)
                })

                if (isMounted) {
                    setBrokerages(brokerages)
                }
            })

        return () => { isMounted = false }
    }, [])

    return (
        <div className='cb-users-per-brokerage-wrapper'>
            <Header className='cb-page-title'>
                Vista de todas las corredurías registradas
                <Header.Subheader>
                    Puedes seleccionar una correduría para acceder a más información
                </Header.Subheader>
            </Header>
            {
                !brokerages &&
                <FullPageLoader text='Cargando...' />
            }
            {
                brokerages &&
                <OverviewGrid
                    items={brokerages}
                    emptyMessage={{
                        title: 'No hay corredurías cargadas',
                        content: 'Actualmente no hay ninguna correduría registrada'
                    }}
                    infoLabel={
                        <PrimaryButton
                            type='button'
                            onClick={() => {
                                history.push(paths.BROKERAGES_DETAIL.replace(':brokerageId', 'create'))
                            }}
                        >
                            Añadir
                        </PrimaryButton>
                    }
                />
            }
        </div>
    )
}

export default Brokerages 
