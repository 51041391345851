import axios from 'axios'

import { axiosInstance } from './serviceConfig'

const quotesEndpoint = '/api/quotes'

const buildQueryParams = params => {
    let queryParams = ''

    if (params) {
        const paramsList = []

        for (let key in params) {
            let value = params[key]
            paramsList.push(`${key}=${value}`)
        }

        if (paramsList.length) {
            queryParams = `?${paramsList.join('&')}`
        }
    }

    return queryParams
}

const getModalities = () => (
    axiosInstance.get(`${quotesEndpoint}/modalities`)
)

const downloadTemplateFile = fileName => (
    axiosInstance.get(`${quotesEndpoint}/download/template/${fileName}`)
)

const downloadUploadedFile = fileName => (
    axiosInstance.get(`${quotesEndpoint}/download/uploaded/${fileName}`)
)

const saveQuote = formData => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CRbyLZZ"'
    }

    return axiosInstance.post(`${quotesEndpoint}/save`, formData, { headers: headers })
}

const uploadPrivateFile = (quoteId, formData) => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CRbyLZZ"'
    }

    return axiosInstance.post(`${quotesEndpoint}/${quoteId}/files/private/upload`, formData, { headers: headers })
}

const updateQuote = (quoteId, formData) => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CRbyLZZ"'
    }

    return axiosInstance.put(`${quotesEndpoint}/${quoteId}`, formData, { headers: headers })
}

const getQuote = quoteId => (
    axiosInstance.get(`${quotesEndpoint}/${quoteId}`)
)

const getQuoteFiles = (quoteId, isPrivate, params) => {
    const queryParams = buildQueryParams(params);
    return axiosInstance.get(`${quotesEndpoint}/${quoteId}/files/${isPrivate ? 'private' : 'public'}${queryParams}`)
}

const createQuoteFolder = (quoteId, folder_name) => {
    return axiosInstance.post(`${quotesEndpoint}/${quoteId}/files/private/folder`, {
        folder_name: folder_name
    })
}

const getDraftQuoteRequests = () => (
    axiosInstance.get(`${quotesEndpoint}/drafts`)
)

const sendQuote = quoteId => (
    axiosInstance.put(`${quotesEndpoint}/${quoteId}/send`)
)

const approveQuote = quoteId => (
    axiosInstance.put(`${quotesEndpoint}/${quoteId}/approve`)
)

const issueQuote = (quoteId, formData) => {
    return axiosInstance.put(`${quotesEndpoint}/${quoteId}/issue`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
}

const quoteQuote = quoteId => (
    axiosInstance.put(`${quotesEndpoint}/${quoteId}/quote`)
)

const rejectQuote = quoteId => (
    axiosInstance.put(`${quotesEndpoint}/${quoteId}/reject`)
)

const notAcceptedQuote = (quoteId, data) => (
    axiosInstance.put(`${quotesEndpoint}/${quoteId}/not-accepted`, { data })
)

const getQuotesAmounts = () => (
    axiosInstance.get(`${quotesEndpoint}/amounts`)
)

const getQuotesAmountsPerBrokerage = (status, quoteType) => {
    const url =
        quoteType ?
            `${quotesEndpoint}/amounts/per-brokerage/${status}?type_id=${quoteType}` :
            `${quotesEndpoint}/amounts/per-brokerage/${status}`
    return axiosInstance.get(url)
}

const getQuotesOverview = (statusRange, quoteType) => (
    axiosInstance.get(`${quotesEndpoint}/overview/${statusRange}?type_id=${quoteType}`)
)

const getBrokerageQuotes = (brokerageId, status, params) => {
    const queryParams = buildQueryParams(params)
    return axiosInstance.get(`${quotesEndpoint}/${status}/${brokerageId}${queryParams}`)
}

const searchQuotes = params => {
    const queryParams = buildQueryParams(params)
    return axiosInstance.get(`${quotesEndpoint}/search${queryParams}`)
}

const getAssignedQuotes = params => {
    const queryParams = buildQueryParams(params)
    return axiosInstance.get(`${quotesEndpoint}/assigned${queryParams}`)
}

const getQuoteCompanies = (quoteId) => (
    axiosInstance.get(`${quotesEndpoint}/${quoteId}/companies`)
)

const saveQuoteCompany = formData => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CRbyLZZ"'
    }

    return axiosInstance.post(`${quotesEndpoint}/companies/save`, formData, { headers: headers })
}



const updateQuoteCompany = (formData) => {
    const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary="CRbyLZZ"'
    }

    return axiosInstance.post(`${quotesEndpoint}/companies/update`, formData, { headers: headers })
}

const deleteQuoteCompany = (id) => {
    return axiosInstance.delete(`${quotesEndpoint}/companies/delete/${id}`)
}


export default {
    downloadTemplateFile,
    downloadUploadedFile,
    getQuotesAmounts,
    getQuotesAmountsPerBrokerage,
    getQuotesOverview,
    getBrokerageQuotes,
    getDraftQuoteRequests,
    getModalities,
    getQuote,
    saveQuote,
    sendQuote,
    approveQuote,
    issueQuote,
    quoteQuote,
    rejectQuote,
    notAcceptedQuote,
    updateQuote,
    searchQuotes,
    getAssignedQuotes,
    saveQuoteCompany,
    updateQuoteCompany,
    getQuoteCompanies,
    deleteQuoteCompany,
    getQuoteFiles,
    createQuoteFolder,
    uploadPrivateFile
}