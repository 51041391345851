import React from 'react'

import { useHistory } from 'react-router-dom'
import { Card, Header } from 'semantic-ui-react'

import './OverviewCard.scss'


const OverviewCard = (props) => {
    const history = useHistory()

    const handleClick = () => {
        if (props.link) {
            history.push(props.link)
        }
    }

    return (
        <Card color='green' className='cb-overview-card' link onClick={handleClick}>
            <Card.Content textAlign='center'>
                <Header size='small' className='cb-overview-title'>
                    {props.title}
                </Header>
            </Card.Content>
            <Card.Content textAlign='center'>
                <Header size='large' className='cb-overview-content'>
                    {props.content}
                </Header>
            </Card.Content>
        </Card>
    )
}

export default OverviewCard