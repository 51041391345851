import React, { useEffect, useState } from "react";
import {
  useClosedQuotesAmount,
  useQuotesAmountByStatus,
  useHealthQuotesAmountByStatus,
  useHealthClosedQuotesAmount,
} from "../../../contexts/PeriodicUpdates";
import { useUserGroups, useUser } from "../../../contexts/Auth";
import { quoteStatus, quoteStatusHints } from "../../../assets/constants/quote";
import { paths } from "../../../config/routes";
import SideBarExternalLink from "../SideBarExternalLink/SideBarExternalLink";
import SideBarLink from "../SideBarLink/SideBarLink";

import centerbrokLogo from "../../../assets/images/common/centerbrok-logo.png";
import "./SideBar.scss";
import { Accordion, Icon } from "semantic-ui-react";
import SideBarTitle from "../SideBarTitle/SideBarTitle";

const SideBar = (props) => {
  const draftQuotesAmount = useQuotesAmountByStatus(quoteStatus.DRAFT);
  const newQuotesAmount = useQuotesAmountByStatus(quoteStatus.NEW);
  const inProgressQuotesAmount = useQuotesAmountByStatus(
    quoteStatus.IN_PROGRESS
  );
  const issuedQuotesAmount = useQuotesAmountByStatus(quoteStatus.ISSUED);
  const quotedQuotesAmount = useQuotesAmountByStatus(quoteStatus.QUOTED);
  const rejectedQuotesAmount = useQuotesAmountByStatus(quoteStatus.REJECTED);
  const notAcceptedQuotesAmount = useQuotesAmountByStatus(
    quoteStatus.NOT_ACCEPTED
  );
  const closedQuotesAmount = useClosedQuotesAmount();

  const draftHeatlhQuotesAmount = useHealthQuotesAmountByStatus(
    quoteStatus.DRAFT
  );
  const newHeatlhQuotessAmount = useHealthQuotesAmountByStatus(quoteStatus.NEW);
  const inProgressHeatlhQuotesAmount = useHealthQuotesAmountByStatus(
    quoteStatus.IN_PROGRESS
  );
  const issuedHeatlhQuotesAmount = useHealthQuotesAmountByStatus(
    quoteStatus.ISSUED
  );
  const rejectedHeatlhQuotesAmount = useHealthQuotesAmountByStatus(
    quoteStatus.REJECTED
  );
  const notAcceptedHeatlhQuotesAmount = useHealthQuotesAmountByStatus(
    quoteStatus.NOT_ACCEPTED
  );
  const closedHeatlhQuotesAmount = useHealthClosedQuotesAmount();
  const userGroups = useUserGroups();
  const user = useUser();
  const isStaff = userGroups.business_department || userGroups.technical;
  const [activeIndex, setActiveIndex] = useState(
    parseInt(localStorage.getItem("activeDashboardIndex"), 10) &&
    (userGroups.technical ? 1 : 0)
  );

  useEffect(() => {
    localStorage.setItem("activeDashboardIndex", activeIndex.toString());
    window.dispatchEvent(
      new CustomEvent("dashboardIndexChanged", {
        detail: { index: activeIndex },
      })
    );
  }, [activeIndex]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index);
  };

  return (
    <nav className="cb-sidebar">
      <header className="cb-sidebar-header">
        <img src={centerbrokLogo} alt="centerbrok-logo" />
        <h3>CenterBrok</h3>
      </header>
      <div className="sidebar-content">
        <SideBarLink link={paths.DASHBOARD} name="Inicio" icon="home" />
        {isStaff && (
          <SideBarLink
            link={paths.BROKERAGES}
            name="Corredurías"
            icon="folder open"
          />
        )}
        {userGroups.business_department && (
          <SideBarLink
            link={paths.ACCOUNTS_MANAGEMENT}
            validForRegex={new RegExp(`^${paths.ACCOUNTS_MANAGEMENT}(/.*)?$`)}
            name="Gestionar altas"
            icon="user plus"
          />
        )}
        {userGroups.brokers && (
          <SideBarLink
            link={paths.QUOTE_REQUEST}
            validForRegex={new RegExp(`^${paths.QUOTE_REQUEST}(/[0-9]+)?$`)}
            name="Solicitud Cotizacion Empresas"
            icon="plus square"
          />
        )}
        {userGroups.brokers && (
          <SideBarLink
            link={paths.QUOTE_HEALTH_REQUEST}
            validForRegex={
              new RegExp(`^${paths.QUOTE_HEALTH_REQUEST}(/[0-9]+)?$`)
            }
            name="Emisiones Salud Colectivo C.B."
            icon="plus square"
          />
        )}
        <SideBarLink link={paths.QUOTES_SEARCH} name="Buscador" icon="search" />
        <SideBarLink
          link={paths.QUOTES_STATISTICS}
          name="Estadísticas"
          icon="chart bar"
        />
        {isStaff && (
          <>
            <SideBarLink
              link={paths.ASSIGNED_QUOTES}
              name="Mis cotizaciones"
              icon="folder open"
            />
          </>
        )}
        <Accordion fluid>
          <>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
            >
              <SideBarTitle icon={"list"} name={"Cotizaciones Empresas"} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {userGroups.brokers && (
                <SideBarLink
                  link={paths.BROKERAGE_QUOTES_BROKER.replace(
                    ":statusSlug",
                    "draft"
                  )}
                  name="Guardadas"
                  icon="save"
                  label={draftQuotesAmount}
                />
              )}
              {isStaff && (
                <SideBarLink
                  link={
                    isStaff
                      ? paths.QUOTES_PER_BROKERAGE.replace(":statusSlug", "new")
                      : paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "new"
                      )
                  }
                  validForRegex={
                    isStaff
                      ? new RegExp(
                        `^${paths.QUOTES_PER_BROKERAGE.replace(
                          ":statusSlug",
                          "new"
                        )}(/[0-9]+)?$`
                      )
                      : new RegExp(
                        `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                          ":statusSlug",
                          "new"
                        )}$`
                      )
                  }
                  name="Nuevas"
                  icon="hourglass start"
                  label={newQuotesAmount}
                />
              )}
              <SideBarLink
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "in-progress"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "in-progress"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "in-progress"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "in-progress"
                      )}$`
                    )
                }
                name="En trámite"
                icon="hourglass half"
                label={inProgressQuotesAmount}
                hint={
                  userGroups.brokers ? quoteStatusHints.inProgress : undefined
                }
              />
              <SideBarLink
                name="Cerradas"
                icon="hourglass end"
                label={closedQuotesAmount}
                hint={userGroups.brokers ? quoteStatusHints.closed : undefined}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "issued"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "issued"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "issued"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "issued"
                      )}$`
                    )
                }
                name="Emitidas"
                icon="hourglass end"
                label={issuedQuotesAmount}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "quoted"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "quoted"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "quoted"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "quoted"
                      )}$`
                    )
                }
                name="Cotizadas"
                icon="hourglass end"
                label={quotedQuotesAmount}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "rejected"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "rejected"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "rejected"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "rejected"
                      )}$`
                    )
                }
                name="Rechazadas"
                icon="hourglass end"
                label={rejectedQuotesAmount}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "not-accepted"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "not-accepted"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "not-accepted"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "not-accepted"
                      )}$`
                    )
                }
                name="No aceptadas"
                icon="hourglass end"
                label={notAcceptedQuotesAmount}
              />
            </Accordion.Content>
          </>

          <>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleClick}
            >
              <SideBarTitle
                icon={"list"}
                name={"Emisiones Salud Colectivo C.B"}
              />
            </Accordion.Title>

            <Accordion.Content active={activeIndex === 1}>
              {userGroups.brokers && (
                <SideBarLink
                  link={paths.BROKERAGE_QUOTES_BROKER.replace(
                    ":statusSlug",
                    "health/draft"
                  )}
                  name="Guardadas"
                  icon="save"
                  label={draftHeatlhQuotesAmount}
                />
              )}
              {isStaff && (
                <SideBarLink
                  link={
                    isStaff
                      ? paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/new"
                      )
                      : paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "health/new"
                      )
                  }
                  validForRegex={
                    new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/new"
                      )}(/[0-9]+)?$`
                    )
                  }
                  name="Nuevas"
                  icon="hourglass start"
                  label={newHeatlhQuotessAmount}
                />
              )}
              <SideBarLink
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "health/in-progress"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "health/in-progress"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/in-progress"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "health/in-progress"
                      )}$`
                    )
                }
                name="En trámite"
                icon="hourglass half"
                label={inProgressHeatlhQuotesAmount}
                hint={
                  userGroups.brokers ? quoteStatusHints.inProgress : undefined
                }
              />
              <SideBarLink
                name="Cerradas"
                icon="hourglass end"
                label={closedHeatlhQuotesAmount}
                hint={userGroups.brokers ? quoteStatusHints.closed : undefined}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "health/issued"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "health/issued"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/issued"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "health/issued"
                      )}$`
                    )
                }
                name="Emitidas"
                icon="hourglass end"
                label={issuedHeatlhQuotesAmount}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "health/rejected"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "health/rejected"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/rejected"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "health/rejected"
                      )}$`
                    )
                }
                name="Rechazadas"
                icon="hourglass end"
                label={rejectedHeatlhQuotesAmount}
              />
              <SideBarLink
                isSecondary
                link={
                  isStaff
                    ? paths.QUOTES_PER_BROKERAGE.replace(
                      ":statusSlug",
                      "health/not-accepted"
                    )
                    : paths.BROKERAGE_QUOTES_BROKER.replace(
                      ":statusSlug",
                      "health/not-accepted"
                    )
                }
                validForRegex={
                  isStaff
                    ? new RegExp(
                      `^${paths.QUOTES_PER_BROKERAGE.replace(
                        ":statusSlug",
                        "health/not-accepted"
                      )}(/[0-9]+)?$`
                    )
                    : new RegExp(
                      `^${paths.BROKERAGE_QUOTES_BROKER.replace(
                        ":statusSlug",
                        "health/not-accepted"
                      )}$`
                    )
                }
                name="No aceptadas"
                icon="hourglass end"
                label={notAcceptedHeatlhQuotesAmount}
              />
            </Accordion.Content>
          </>
        </Accordion>
      </div>
      <div className="powered-by">
        <SideBarExternalLink
          link="https://intranet.centerbrok.es/ppc2/"
          name="Plataforma de Productos CenterBrok"
        />
      </div>
    </nav>
  );
};

export default SideBar;
