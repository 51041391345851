import React, { useEffect, useState } from 'react'

import { Form, Header, Transition } from 'semantic-ui-react'

import {
    initializeForm,
    updateValue,
    updateVisibility,
    useFormDispatch,
    useFormState
} from '../../contexts/Form'

import { Section } from '../UI/Form'

import './QuoteForm.scss'

const QuoteForm = (props) => {
    const formState = useFormState()
    const dispatch = useFormDispatch()
    const [formRefreshed, setFormRefreshed] = useState(false)

    const applyVisualizationTrigger = (triggerOption) => {
        triggerOption.show?.forEach(itemName => {
            updateVisibility(dispatch, itemName, true)
        })

        triggerOption.hide?.forEach(itemName => {
            updateVisibility(dispatch, itemName, false)
        })
    }

    const launchVisualizationTrigger = (name) => {
        if (!formState.config?.triggers?.visualization?.hasOwnProperty(name)) {
            return
        }

        const triggerConfig = formState.config.triggers.visualization[name]
        const triggerOptions = triggerConfig.options
        const triggerFallbackAction = triggerConfig.fallbackAction
        const value = formState.values[name]?.raw
        let changeMade = false

        triggerOptions.forEach(triggerOption => {
            if (!triggerOption.values.includes(value)) {
                return
            }

            applyVisualizationTrigger(triggerOption)

            changeMade = true
        })

        if (!changeMade) {
            applyVisualizationTrigger(triggerFallbackAction)
        }
    }

    const launchAdditionTrigger = (name) => {
        if (!formState.config?.triggers?.addition?.hasOwnProperty(name)) {
            return
        }

        const additionTriggers = formState.config.triggers.addition
        const targetField = formState.config.triggers.addition[name]
        let total = 0

        for (const [source, target] of Object.entries(additionTriggers)) {
            const sourceValue = formState.values[source]?.raw

            if ((target === targetField) && (typeof sourceValue === 'number')) {
                total += sourceValue
            }
        }

        if (formState.values[targetField]?.raw !== total) {
            updateValue(dispatch, targetField, { raw: total })
        }
    }

    const launchTriggers = (name, fromInit = false) => {
        launchVisualizationTrigger(name)

        if (!fromInit) {
            launchAdditionTrigger(name)
        }
    }

    const refreshForm = () => {
        const names = Object.keys(formState.values)

        names.forEach(name => {
            launchTriggers(name, true)
        })
    }

    useEffect(() => {
        let isMounted = true

        import(`../../assets/formTemplates/${props.modality.code.toLowerCase()}.js`)
            .then(res => {
                if (isMounted) {
                    initializeForm(
                        dispatch,
                        props.modality.code,
                        res.formConfig,
                        props.values,
                        props.hiddenFields || [],
                        props.readOnly || false
                    )
                }
            })

        return () => { isMounted = false }
    }, [])

    useEffect(() => {
        const changeName = formState.lastChange
        launchTriggers(changeName)
    }, [formState.changeCounter])

    useEffect(() => {
        if (formState.initialized && !formRefreshed) {
            refreshForm()
            setFormRefreshed(true)
        }
    }, [formState.initialized])

    return (
        <Transition.Group animation='fade' duration={props.animationDuration || 0}>
            {
                formState.config &&
                <Form className='cb-quote-form'>
                    <Header size='large' className='cb-form-header'>
                        {props.modality.long_name}
                    </Header>
                    {
                        formState.config.sections.map((section, index) => (
                            (formState.visibilities[section.name] !== false)
                                ? <Section
                                    key={index}
                                    className={index === 0 && !section.withTopMargin ? 'cb-marginless-top' : null}
                                    {...section} />
                                : null
                        ))
                    }
                </Form>
            }
        </Transition.Group>
    )
}

export default QuoteForm