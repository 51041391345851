import React, { useEffect, useState } from 'react'

import FieldWrapper from '../FieldWrapper/FieldWrapper'


const FooterText = (props) => {
    return (
        props.text ?
            <>
                <div dangerouslySetInnerHTML={{ __html: props.text }} />
            </> : <FieldWrapper {...props}>
                <br />
                <b>
                    IMPORTANTE
                    <br></br>
                    <ul>
                        <li>La documentación debe ser legible y estar cumplimentada en su totalidad, todas las preguntas respondidas, fechado y firmado</li>
                        <li>Si se indican patologías en el cuestionario de salud deben detallarse lo mejor posible, indicarse fechas y si ha habido secuelas o no</li>
                        <li>Se pone a disposición en este mismo apartado del IPID y Nota informativa de la compañía que se debe presentar al cliente en el momento de la contratación.</li>
                    </ul>
                </b>
            </FieldWrapper>
    )
}

export default FooterText