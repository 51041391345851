import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Header, Modal } from 'semantic-ui-react'

import { quotePdfFilename, quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import * as utils from '../../assets/js/utils'
import quotesService from '../../services/quotesService'
import { paths } from '../../config/routes'

import { useUserGroups } from '../../contexts/Auth'
import { useErrorState } from '../../contexts/ErrorHandling'
import {
    useQuotesAmountByStatus
} from '../../contexts/PeriodicUpdates'

import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'
import { QuoteManagementPanel } from '../../components/QuoteManagement'

import './QuoteManagement.scss'

const QuoteManagement = () => {
    const { quoteId } = useParams()
    const history = useHistory()
    const { setErrorDialog } = useErrorState()
    const userGroups = useUserGroups()
    const [quote, setQuote] = useState(null)

    const refreshQuoteData = () => (
        quotesService.getQuote(quoteId)
            .then(res => {
                const quoteData = res.data

                if ((quoteData.status.code === quoteStatus.DRAFT) ||
                    ((quoteData.status.code === quoteStatus.NEW) && userGroups.brokers)) {
                    history.push(paths.DASHBOARD)
                    return
                }

                const publicFiles = quoteData.uploaded_files.public
                const importantIndexes = []

                publicFiles.forEach((file, index) => {
                    if (file.important) {
                        importantIndexes.push(index)
                    }
                })

                importantIndexes.forEach(index => {
                    const importantFile = publicFiles[index]
                    publicFiles.splice(index, 1)
                    publicFiles.splice(0, 0, importantFile);
                })

                setQuote(quoteData)
            })
            .catch(error => {
                if (error?.response?.status === 404) {
                    history.push(paths.NOT_FOUND)
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar la cotización. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando cotización',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    )

    useEffect(() => {
        if (!quoteId) {
            history.push(paths.DASHBOARD)
            return
        }

        setQuote(null)
        refreshQuoteData()
    }, [quoteId])

    return (
        <>
            <Helmet>
                <title>Gestionar cotización - CenterRisk</title>
            </Helmet>
            {
                !quote &&
                <FullPageLoader text='Cargando cotización...'/>
            }
            {
                quote &&
                <div className='cb-quote-management-wrapper'>
                    <Header className='cb-page-title'>
                        {
                            quote.status.code === quoteStatus.NEW
                            ? <>
                                  Revisa la información para tramitar la solicitud de cotización
                              </>
                            : <>
                                  {
                                      userGroups.business_department &&
                                      <>Correduría <span> {quote.requester.brokerage.name} </span> - </>
                                  }
                                  Cotización nº <span>{quote.number}</span>
                              </>
                        }
                        <Header.Subheader>
                            {
                                quote.status.code === quoteStatus.NEW
                                ? <>
                                      Fecha de entrada: {utils.datetimeToStr(quote.sent_at)}
                                  </>
                                : <>
                                      Cotización solicitada por
                                      <strong> {quote.requester.first_name} {quote.requester.last_name}</strong>. Estado actual:
                                      <strong> {quoteStatusNames[quote.status.code].singular.toLowerCase()}</strong>
                                  </>
                            }
                        </Header.Subheader>
                    </Header>
                    <QuoteManagementPanel quote={quote} refreshQuoteData={refreshQuoteData}/>
                </div>
            }
        </>
    )
}

export default QuoteManagement 
