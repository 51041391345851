import React, { useEffect, useState } from 'react'

import {
    Button,
    Header,
    Icon,
    Image,
    Modal,
    Pagination,
    Table
} from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'
import { useAccessRequestsAmount } from '../../../contexts/PeriodicUpdates'
import { paths } from '../../../config/routes'
import accountsService from '../../../services/accountsService'
import * as utils from '../../../assets/js/utils'
import FullPageLoader from '../../UI/FullPageLoader/FullPageLoader'
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../UI/SecondaryButton/SecondaryButton'
import AccessRequestsPagination from '../AccessRequestsPagination/AccessRequestsPagination'

import successImage from '../../../assets/images/common/success.png'

import './AccessRequestsTable.scss'

const AccessRequestsTable = (props) => {
    const accessRequestsAmount = useAccessRequestsAmount()
    const { setErrorDialog } = useErrorState()
    const [init, setInit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [requests, setRequests] = useState(null)
    const [pageData, setPageData] = useState(null)
    const [confirmationModalConfig, setConfirmationModalConfig] = useState(null)
    const [confirmationButtonsDisabled, setConfirmationButtonsDisabled] = useState(false)

    const getAccessRequestsPage = (pageNumber, addLoader = true) => {
        if (addLoader) {
            setLoading(true)
        }

        return accountsService.getAccessRequests(pageNumber)
            .then(res => {
                const pageData = {...res.data}
                delete pageData.results
                
                setPageData(pageData)
                setRequests(res.data.results)
                setLoading(false)

                if (init) {
                    setInit(false)
                }
            })
            .catch(error => {
                if ((error?.response?.status === 404) && (pageNumber > 1)) {
                    return getAccessRequestsPage(pageNumber - 1, addLoader)
                }

                setLoading(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar las solicitudes de acceso. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando solicitudes',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    const handleAccessRequest = (action, requestId) => {
        setConfirmationButtonsDisabled(true)

        let promise

        if (action === 'ACCEPT') {
            promise = accountsService.acceptAccessRequest(requestId)
        } else {
            promise = accountsService.rejectAccessRequest(requestId)
        }

        promise
            .then(() => (
                getAccessRequestsPage(pageData.currentPage, false)
            ))
            .then(() => {
                setConfirmationModalConfig(null)
                setConfirmationButtonsDisabled(false)
            })
            .catch(error => {
                setConfirmationModalConfig(null)
                setConfirmationButtonsDisabled(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al gestionar la solicitud. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error gestionando solicitud',
                    description: errorMessage
                })
            })
    }

    useEffect(() => {
        if (init) {
            getAccessRequestsPage()
        } else if (accessRequestsAmount > 0) {
            getAccessRequestsPage(pageData.currentPage, false)
        } else {
            getAccessRequestsPage(1, false)
        }
    }, [accessRequestsAmount])

    return (
        <>
            {
                !loading && (requests?.length > 0) &&
                <>
                    <Table stackable striped basic='very' size='large' className='cb-access-requests-table'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={4}>Nombre correduría</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Administrador</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Fecha de solicitud</Table.HeaderCell>
                                <Table.HeaderCell width={4}>Acciones</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                (!loading) &&
                                requests.map((request, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            <strong>
                                                {request.brokerage.name}
                                            </strong>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {`${request.name} ${request.surname}`}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {utils.datetimeToStr(request.created_at)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                basic
                                                compact
                                                className='cb-access-request-action-button cb-accept-access-request-button'
                                                onClick={() => setConfirmationModalConfig({
                                                    action: 'ACCEPT',
                                                    requestId: request.id
                                                })}>
                                                ACEPTAR
                                            </Button>
                                            <Button
                                                basic
                                                compact
                                                className='cb-access-request-action-button cb-reject-access-request-button'
                                                onClick={() => setConfirmationModalConfig({
                                                    action: 'REJECT',
                                                    requestId: request.id
                                                })}>
                                                RECHAZAR
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table>
                    {
                        (pageData.lastPage > 1) &&
                        <AccessRequestsPagination
                            currentPage={pageData.currentPage}
                            lastPage={pageData.lastPage}
                            onPageChange={(pageNumber) => getAccessRequestsPage(pageNumber)}
                            previousPage={pageData.previousPage}
                            nextPage={pageData.nextPage}/>
                    }
                </>
            }
            {
                !loading && (requests?.length === 0) &&
                <div className='cb-no-pending-requests'>
                    <Image src={successImage} size='big' centered/>
                    <Header size='huge'>
                        <span className='cb-dark-green'>¡Todo al día!</span>
                        <Header.Subheader>
                            No hay ninguna solicitud de acceso pendiente de tramitar
                        </Header.Subheader>
                    </Header>
                </div>
            }
            {
                loading &&
                <FullPageLoader text='Cargando solicitudes...'/>
            }
            <Modal open={confirmationModalConfig !== null}
                   size='mini'
                   dimmer='blurring'>
                <Modal.Header>
                    {`${confirmationModalConfig?.action === 'ACCEPT' ? 'Aceptar' : 'Rechazar'} solicitud`}
                </Modal.Header>
                <Modal.Content>
                    {`¿Seguro que quieres ${confirmationModalConfig?.action === 'ACCEPT' ? 'aceptar' : 'rechazar'} la solicitud de acceso?`}
                </Modal.Content>
                <Modal.Actions>
                    <PrimaryButton
                        compact
                        disabled={confirmationButtonsDisabled}
                        onClick={() => setConfirmationModalConfig(null)}>
                        Cancelar
                    </PrimaryButton>
                    <SecondaryButton
                        compact
                        loading={confirmationButtonsDisabled}
                        disabled={confirmationButtonsDisabled}
                        onClick={() => handleAccessRequest(confirmationModalConfig?.action, confirmationModalConfig?.requestId)}>
                        {`Sí, ${confirmationModalConfig?.action === 'ACCEPT' ? 'aceptar' : 'rechazar'}`}
                    </SecondaryButton>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default AccessRequestsTable