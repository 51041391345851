import React, { useEffect, useRef, useState } from 'react'

import { Header, Icon, Input, Label, List, Segment } from 'semantic-ui-react'

import { getFileIconProps } from '../../../../../assets/js/utils'

import quotesService from '../../../../../services/quotesService'

import { useErrorState } from '../../../../../contexts/ErrorHandling'
import {
    updateValue,
    useValue,
    useFormDispatch
} from '../../../../../contexts/Form'

import FieldWrapper from '../FieldWrapper/FieldWrapper'
import InfoTooltip from '../../../InfoTooltip/InfoTooltip'

import './FileField.scss'


const FileField = (props) => {
    const fileDropAreaRef = useRef(null)
    const fileInputRef = useRef(null)
    const [downloadingFiles, setDownloadingFiles] = useState({})
    const [draggingOver, setDraggingOver] = useState(false)
    const dispatch = useFormDispatch()
    const files = useValue(props.name, {})
    const { setErrorDialog } = useErrorState()

    const getFieldId = () => props.id ? props.id : props.name

    const getBasicProps = () => {
        return {
            id: getFieldId(),
            name: props.name,
            multiple: props.multiple !== false
        }
    }

    const downloadFile = (index, file, isTemplate) => {
        setDownloadingFiles({...downloadingFiles, [index]: true})

        const response = isTemplate
                         ? quotesService.downloadTemplateFile(file)
                         : quotesService.downloadUploadedFile(file)

        response
            .then(res => {
                const tempAnchor = document.createElement('a')

                if (file.endsWith('.pdf')) {
                    tempAnchor.setAttribute('rel', 'noopener')
                    tempAnchor.setAttribute('target', '_blank')
                } else {
                    tempAnchor.setAttribute('download', true)
                }

                tempAnchor.setAttribute('href', res.data.file_url)
                tempAnchor.style.display = 'none'
                
                document.body.appendChild(tempAnchor)
                tempAnchor.click()
                document.body.removeChild(tempAnchor)

                setDownloadingFiles({...downloadingFiles, [index]: false})
            })
            .catch(() => {
                setErrorDialog({
                    title: 'Error al descargar archivo',
                    description: `Ha tenido lugar un error al descargar el archivo.
                                  Por favor, inténtalo de nuevo más tarde o contacta con el
                                  administrador de la plataforma.`
                })
                setDownloadingFiles({...downloadingFiles, [index]: false})
            })
    }

    const addDropAreaListeners = () => {
        const dropArea = fileDropAreaRef.current

        if (!dropArea) {
            return
        }
        
        dropArea.addEventListener('dragenter', handleDrag)
        dropArea.addEventListener('dragleave', handleDragOut)
        dropArea.addEventListener('dragover', handleDrag)
        dropArea.addEventListener('drop', handleDrop)
    }

    const removeDropAreaListeners = () => {
        const dropArea = fileDropAreaRef.current

        if (!dropArea) {
            return;
        }

        dropArea.removeEventListener('dragenter', handleDrag)
        dropArea.removeEventListener('dragleave', handleDragOut)
        dropArea.removeEventListener('dragover', handleDrag)
        dropArea.removeEventListener('drop', handleDrop)
    }

    const handleDrag = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(true)
    }

    const handleDragOut = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(false)
    }

    // This function handles when files are 'dropped'
    const handleDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDraggingOver(false)

        const fileInput = fileInputRef.current
        fileInput.files = event.dataTransfer.files

        handleChange(event.dataTransfer.files)
    }

    // This function handles when files are manually selected
    const handleFileSelection = (event) => {
        handleChange(event.target.files)
    }

    // This is the 'sink' function for all file selection changes
    const handleChange = (addedFiles) => {
        if(props.onFilesSelected){
            props.onFilesSelected(addedFiles);
        }
        const newFiles = {
            ...files,
            'pending': [...Array.from(addedFiles), ...files.pending? files.pending : []]
        }

        updateValue(dispatch, props.name, newFiles)
        
    }

    const generateFileRequirement = (requirement, index) => {
        const itemClassProp = {
            className: 'cb-file-requirement'
        }

        if (requirement.fileName) {
            itemClassProp.className += ' cb-with-label'
        }

        return (
            <List.Item key={index} {...itemClassProp}>
                <Icon name='right triangle'/>
                <List.Content>
                    {requirement.text}
                    {
                        requirement.fileName &&
                        generateFileItem(null, requirement.fileName, null, true, true)
                    }
                </List.Content>
            </List.Item>
        )
    }

    const generateFileItem = (index, fileName, filePath, downloadable, isTemplate, removable = true) => {
        const variableProps = {}

        if (downloadable) {
            const file = filePath ? filePath : fileName
            variableProps.as = 'a'
            variableProps.onClick = () => downloadFile(index, file, isTemplate)
        }

        return (
            <Label {...variableProps} className='cb-file-item' key={index}>
                <Icon {...getFileIconProps(fileName)}/>
                {fileName}
                {
                    downloadable &&
                    <Label.Detail>
                        {
                            downloadingFiles[index] === true
                            ? <Icon loading name='circle notch'/>
                            : <Icon name='download'/>
                        }
                    </Label.Detail>
                }

{
                    removable &&
                    <Label.Detail>
                        {
                            <Icon 
                                name='close'
                                onClick={() => removeFile(index)}
                            />
                        }
                    </Label.Detail>
                }
            </Label>
        )
    }

    const removeFile = (index) =>  {
        files.pending.splice(index, 1);

        const newFiles = {
            ...files,
            'pending': [...files.pending ? files.pending : []]
        }

        updateValue(dispatch, props.name, newFiles)
    }

    useEffect(() => {
        addDropAreaListeners()
        updateValue(dispatch, props.name, files)
        return removeDropAreaListeners
    }, [])

    return (
        <FieldWrapper
            isFileField={true}
            prevRequirementContition={!Boolean(files?.alreadyUploaded?.length)}
            {...props}>
            {/*
                The 'prevRequirementContition' prop tells:
                'No previously uploaded files must exist in order to show an error for empty value'.

                Or, in other words:
                'If some file was previously uploaded, the value is correct even if no new files are uploaded'.
            */}
            {
                props.label &&
                <label>
                    {props.label}
                    {
                        props.info &&
                        <InfoTooltip text={props.info}/>
                    }
                </label>
            }
            {
                props.requirements?.length &&
                <Segment raised className='cb-files-requirements'>
                    <Label ribbon>Documentación a aportar</Label>
                    <List>
                        {
                            props.requirements.map((requirement, index) => (
                                generateFileRequirement(requirement, index)
                            ))
                        }
                    </List>
                </Segment>
            }
            <div className='cb-file-upload-container'>
                <label
                    htmlFor={getFieldId()}
                    ref={fileDropAreaRef}
                    className={
                        `${draggingOver ? 'cb-dragged-over' : null}${(files?.pending?.length || files?.alreadyUploaded?.length) ? ' cb-files-selected': ''}`
                    }>
                    <div>
                        <span>Subir archivos</span>
                        <Icon name='upload'/>
                    </div>
                    <span>
                        {props.footerText || 'Haz clic para seleccionar archivos o arrástralos aquí directamente'}
                    </span>
                </label>
                <input
                    {...getBasicProps()}
                    ref={fileInputRef}
                    type='file'
                    onChange={handleFileSelection}/>
                {
                    (files?.pending?.length > 0 || files?.alreadyUploaded?.length > 0) &&
                    <div className='cb-files-list'>
                        {
                            files?.alreadyUploaded?.length > 0 &&
                            <>
                                <Header size='tiny' className='cb-dark-green' dividing>Ya subidos</Header>
                                <Label.Group>
                                    {Array.from(files.alreadyUploaded).map((file, index) => (
                                        generateFileItem(index, file.name, file.path, true, false, false)
                                    ))}
                                </Label.Group>
                            </>
                        }
                        {
                            files?.pending?.length > 0 &&
                            <>
                                <Header size='tiny' className='cb-dark-green' dividing>Pendientes de subir</Header>
                                <Label.Group>
                                    {Array.from(files.pending).map((file, index) => (
                                        generateFileItem(index, file.name)
                                    ))}
                                </Label.Group>
                            </>
                        }
                    </div>
                }
            </div>
        </FieldWrapper>
    )
}

export default FileField