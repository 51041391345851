import React, { useEffect, useState } from 'react'

import { Card, Dimmer, Header, Image, Loader, Segment, Transition } from 'semantic-ui-react'

import { useErrorState } from '../../../contexts/ErrorHandling'

import quotesService from '../../../services/quotesService'

import './ModalityCards.scss'

const ModalityCards = (props) => {
    const [cardsLoading, setCardsLoading] = useState(true)
    const [cardsVisible, setCardsVisible] = useState(false)
    const [modalities, setModalities] = useState([])

    const { setErrorDialog } = useErrorState()

    const animationDuration = 300

    const selectModality = (modality) => {
        setCardsVisible(false)
        
        setTimeout(() => {
            props.selectModality(modality)
        }, (animationDuration - 100))
    }

    useEffect(() => {
        let isMounted = true
        let modalities

        quotesService.getModalities()
            .then(res => {
                if(props.category){
                    modalities = res.data.filter(modality => modality.type_id === props.category)
                }
                // Temp
                /*modalities.push({
                    "code": "UNPAID_RENT",
                    "display_order": 20,
                    "short_name": "Impago alquiler",
                    "long_name": "Impago alquiler",
                    "image": "/static/media/generic.d1f22f0c.jpg"
                });*/

                return Promise.all(
                    modalities.map((modality) => (
                        import(`../../../assets/images/quotes/${modality.code.toLowerCase()}.jpg`)
                    ))
                )
            })
            .then(res => {
                for (let i = 0; i < modalities.length; i++) {
                    modalities[i].image = res[i].default
                }

                return modalities
            })
            .then(res => {
                if (isMounted) {
                    setModalities(modalities)
                    setCardsLoading(false)
                    setCardsVisible(true)
                }
            })
            .catch(res => {
                if (isMounted) {
                    setCardsLoading(false)
                    setErrorDialog({
                        title: 'Error al cargar modalidades',
                        description: `Ha tenido lugar un error al cargar las modalidades
                                      de cotización.`
                    })
                }
            })

        return () => { isMounted = false }
    }, [])

    return (
        <Segment attached loading={cardsLoading} className={`cb-modality-cards-segment${cardsLoading ? ' cb-loading' : ''}`}>
            <Transition.Group animation='fade' duration={animationDuration}>
                {
                    cardsVisible &&
                    <div>
                        <Card.Group stackable itemsPerRow='four'>
                            {
                                modalities.map((modality) => (
                                    <Card
                                        link
                                        raised
                                        className='cb-modality-card'
                                        key={modality.code}
                                        onClick={() => selectModality(modality)}>
                                        <Card.Content>
                                            <Card.Header textAlign='center'>
                                                <Header className='cb-modality-name'>{modality.short_name}</Header>
                                            </Card.Header>
                                        </Card.Content>
                                        <Image src={modality.image} wrapped ui={false} className='cb-modality-picture'/>
                                    </Card>
                                ))
                            }
                        </Card.Group>
                    </div>
                }
            </Transition.Group>
        </Segment>
    )
}

export default ModalityCards