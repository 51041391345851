// https://soshace.com/react-user-login-authentication-using-usecontext-and-usereducer/
import React from 'react'
import {
    AccessRequests,
    AccountsManagement,
    BrokerageQuotes,
    BrokeragesQuotesOverview,
    BrokerageUsers,
    Brokerages,
    BrokeragesDetail,
    Dashboard,
    Login,
    AssignedQuotes,
    NewPassword,
    NotFound,
    QuoteManagement,
    QuoteRequest,
    QuoteHealthRequest,
    QuotesSearch,
    QuotesStatistics,
    UsersPerBrokerage
} from '../pages'

import { authGroup } from '../assets/constants/auth'

const paths = {
    LOGIN: '/login',
    PASSWORD_RESET: '/reset-password/:resetKey',
    DASHBOARD: '/',
    HEALTH_DASHBOARD: '/health',
    ACCOUNTS_MANAGEMENT: '/accounts-management',
    ACCESS_REQUESTS: '/accounts-management/access-requests',
    QUOTE_REQUEST: '/quote-request',
    QUOTE_HEALTH_REQUEST: '/quote-health-request',
    RESUME_QUOTE_REQUEST: '/quote-request/:quoteId',
    UNFINISHED_QUOTE_REQUESTS: '/quote-requests/unfinished',
    USERS_PER_BROKERAGE: '/accounts-management/users-per-brokerage',
    BROKERAGE_USERS: '/accounts-management/users-per-brokerage/:brokerageId',
    QUOTES_PER_BROKERAGE: '/quotes-overview/:statusSlug',
    HEALTH_QUOTES_PER_BROKERAGE: '/quotes-overview/health/:statusSlug',
    BROKERAGE_QUOTES_BUSINESS: '/quotes-overview/:statusSlug/:brokerageId',
    BROKERAGE_HEALTH_QUOTES_BUSINESS: '/quotes-overview/health/:statusSlug/:brokerageId',
    BROKERAGE_QUOTES_BROKER: '/quotes/:statusSlug',
    BROKERAGE_HEALTH_QUOTES_BROKER: '/quotes/health/:statusSlug',
    QUOTE_MANAGEMENT: '/quote-management/:quoteId',
    QUOTES_SEARCH: '/quotes-search',
    QUOTES_STATISTICS: '/quotes-statistics',
    ASSIGNED_QUOTES: '/assigned-quotes',
    BROKERAGES: '/brokerages',
    BROKERAGES_DETAIL: '/brokerages/:brokerageId',
    NOT_FOUND: '/not-found'
}

const routes = [
    {
        path: paths.LOGIN,
        component: Login,
        isPrivate: false
    },
    {
        path: paths.PASSWORD_RESET,
        component: NewPassword,
        isPrivate: false
    },
    {
        path: paths.DASHBOARD,
        component: Dashboard,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.HEALTH_DASHBOARD,
        component: Dashboard,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.ACCOUNTS_MANAGEMENT,
        component: AccountsManagement,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.ACCESS_REQUESTS,
        component: AccessRequests,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTE_REQUEST,
        component: QuoteRequest,
        isPrivate: true,
        allowedGroups: [authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTE_HEALTH_REQUEST,
        component: QuoteHealthRequest,
        isPrivate: true,
        allowedGroups: [authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.RESUME_QUOTE_REQUEST,
        component: QuoteRequest,
        isPrivate: true,
        allowedGroups: [authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.USERS_PER_BROKERAGE,
        component: UsersPerBrokerage,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGE_USERS,
        component: BrokerageUsers,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTES_PER_BROKERAGE,
        component: BrokeragesQuotesOverview,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.HEALTH_QUOTES_PER_BROKERAGE,
        component: BrokeragesQuotesOverview,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGE_QUOTES_BUSINESS,
        component: BrokerageQuotes,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGE_HEALTH_QUOTES_BUSINESS,
        component: BrokerageQuotes,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGE_QUOTES_BROKER,
        component: BrokerageQuotes,
        isPrivate: true,
        allowedGroups: [authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGE_HEALTH_QUOTES_BROKER,
        component: BrokerageQuotes,
        isPrivate: true,
        allowedGroups: [authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTE_MANAGEMENT,
        component: QuoteManagement,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTES_SEARCH,
        component: QuotesSearch,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.QUOTES_STATISTICS,
        component: QuotesStatistics,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.BROKERS, authGroup.TECHNICAL]
    },
    {
        path: paths.ASSIGNED_QUOTES,
        component: AssignedQuotes,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGES,
        component: Brokerages,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        path: paths.BROKERAGES_DETAIL,
        component: BrokeragesDetail,
        isPrivate: true,
        allowedGroups: [authGroup.BUSINESS_DEPARTMENT, authGroup.TECHNICAL]
    },
    {
        component: NotFound
    }
]

export {
    paths,
    routes
}