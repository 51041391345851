import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

import documentationService from '../../services/documentationService'
import quotesService from '../../services/quotesService'

import { ModalityCards, RequestConfirmation, RequestForm, RequestSteps } from '../../components/QuoteRequest'
import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'

import { useErrorState } from '../../contexts/ErrorHandling'
import { FormProvider } from '../../contexts/Form'

import { paths } from '../../config/routes'

import { requestStep } from '../../assets/constants/quote'

import './QuoteHealthRequest.scss'

const QuoteHealthRequest = () => {
    const { quoteId } = useParams()
    const [activeStep, setActiveStep] = useState(requestStep.MODALITY)
    const [formPdfStructure, setFormPdfStructure] = useState(null)
    const [selectedModality, setSelectedModality] = useState(null)
    const [quotePdfUrl, setQuotePdfUrl] = useState(null)
    const [createdQuoteId, setCreatedQuoteId] = useState(quoteId || null)
    const [loaded, setLoaded] = useState(false)
    const [formValues, setFormValues] = useState(null)
    const { setErrorDialog } = useErrorState()
    const [mounted, setMounted] = useState(true)
    const history = useHistory()


    const selectModality = (modality) => {
        setSelectedModality(modality)
        setActiveStep(requestStep.FORM)
    }

    const loadForm = (quoteId) => {
        setLoaded(false)

        documentationService.deleteQuotePdf(quoteId)
            .then(() => documentationService.deleteQuoteDocx(quoteId))
            .then(() => quotesService.getQuote(quoteId))
            .then(res => {
                if (mounted) {
                    res.data.data.quoteRequestFilesUpload = {
                        alreadyUploaded: res.data.uploaded_files.public
                    }

                    setSelectedModality(res.data.modality)
                    setFormValues(res.data.data)
                    setActiveStep(requestStep.FORM)
                    setLoaded(true)
                }
            })
            .catch(() => {
                if (mounted) {
                    setErrorDialog({
                        title: 'Error al cargar la solicitud',
                        description: `Ha tenido lugar un error al cargar los datos de la solicitud guardada.
                                      Por favor, inténtalo de nuevo más tarde o contacta con el administrador
                                      de la plataforma.`,
                        onConfirm: () => history.push(paths.DASHBOARD)
                    })
                    setLoaded(true)
                }
            })
    }

    const showQuotePdf = (fileUrl) => {
        setQuotePdfUrl(fileUrl)
        setActiveStep(requestStep.CONFIRMATION)
    }

    const cancelForm = () => {
        if (quoteId) {
            history.push(paths.QUOTE_REQUEST)
        } else {
            setSelectedModality(null)
            setQuotePdfUrl(null)
            setCreatedQuoteId(null)
            setFormValues(null)
            setActiveStep(requestStep.MODALITY)
        }
    }

    useEffect(() => {
        setMounted(true)

        if (createdQuoteId) {
            loadForm(createdQuoteId)
        } else {
            setLoaded(true)
        }

        return () => setMounted(false)
    }, [])

    return (
        <>
            <Helmet>
                <title>Nueva solicitud - CenterRisk</title>
            </Helmet>
            <div className='cb-quote-request-wrapper'>
                <Header className='cb-page-title'>
                    Completa el formulario para solicitar emisión
                </Header>
                {
                    loaded &&
                    <>
                        <RequestSteps activeStep={activeStep}/>
                        {
                            (activeStep === requestStep.MODALITY) &&
                            <ModalityCards category={2} selectModality={selectModality}/>
                        }
                        {
                            (activeStep === requestStep.FORM) &&
                            <FormProvider>
                                <RequestForm
                                    modality={selectedModality}
                                    cancelForm={cancelForm}
                                    setCreatedQuoteId={setCreatedQuoteId}
                                    setFormPdfStructure={setFormPdfStructure}
                                    showQuotePdf={showQuotePdf}
                                    values={formValues}
                                    quoteId={createdQuoteId}/>
                            </FormProvider>
                        }
                        {
                            (activeStep === requestStep.CONFIRMATION) &&
                            <RequestConfirmation
                                quoteId={createdQuoteId}
                                pdfUrl={quotePdfUrl}
                                formPdfStructure={formPdfStructure}
                                reloadForm={() => loadForm(createdQuoteId)}/>
                        }
                    </>
                }
            </div>
            {
                !loaded &&
                <FullPageLoader text='Cargando datos...'/>
            }
        </>
    )
}

export default QuoteHealthRequest 
