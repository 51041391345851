import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";

import {
  login,
  scheduleTokenRefresh,
  useAuthDispatch,
  useAuthState,
} from "../../../contexts/Auth";
import { useUrlQuery } from "../../../hooks";

import { paths } from "../../../config/routes";

import "./LoginForm.scss";

const LoginForm = () => {
  const [data, setData] = useState({});
  const [loginError, setLoginError] = useState(null); // Para manejar errores de acceso no autorizado
  const [isVisiblePassword, setPasswordVisibility] = useState(false);

  const dispatch = useAuthDispatch();
  const urlQuery = useUrlQuery();
  const history = useHistory();

  const allowedUsers = [
    "abelfriasdiaz@yahoo.es",
    "franco.perez03@gmail.com",
    "sheila.administracion@centerbrok.es",
    "siniestros@centerbrok.es",
    "departamentoempresas@centerbrok.com",
    "departamentoempresas@centerbrok.es",
    "beat.cuenca@gmail.com",
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // if (!allowedUsers.includes(data.username)) {
    //   console.log(`llego handle submit`);
    //   setLoginError("Usuario no autorizado");
    //   return;
    // }

    try {
      login(dispatch, data)
        .then((res) => {
          if (res && res.currentUser) {
            const nextUrl = urlQuery.get("next");
            scheduleTokenRefresh(dispatch);
            history.push(nextUrl ? nextUrl : paths.DASHBOARD);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoginError(
            "Error al iniciar sesión, por favor intente nuevamente."
          );
        });
    } catch (error) {
      console.error(error);
      setLoginError("Error interno del sistema.");
    }
  };

  return (
    <Form
      error={loginError ? true : false}
      className="cb-login-form"
      id="cb-login-form"
      onSubmit={handleSubmit}
    >
      <Form.Input
        error={!!loginError}
        label="Correo electrónico"
        placeholder="Correo electrónico"
        icon="envelope"
        iconPosition="left"
        name="username"
        onChange={handleChange}
      />
      <Form.Input
        error={!!loginError}
        label="Contraseña"
        placeholder="Contraseña"
        icon="lock"
        iconPosition="left"
        name="password"
        type={isVisiblePassword ? "text" : "password"}
        action={{
          icon: "eye",
          className: "cb-show-password-button",
          type: "button",
          onClick: () => setPasswordVisibility(!isVisiblePassword),
        }}
        onChange={handleChange}
      />
      {loginError && <Message error content={loginError} />}
    </Form>
  );
};

export default LoginForm;
