import React from 'react'

import './SideBarExternalLink.scss'

const SideBarExternalLink = (props) => {
    return (
        <a href={props.link} target='_blank' rel='noreferrer' className='cb-sidebar-external-link'>
            <div className='cb-sidebar-external-link-content'>
                {props.name}
            </div>
        </a>
    )
}

export default SideBarExternalLink