import React, { useEffect, useState } from 'react'

import { defaults, Doughnut } from 'react-chartjs-2'

import './QuotesAmountsChart.scss'


const QuotesAmountsChart = (props) => {
    defaults.font = {
        family: "'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'"
    }

    return (
        <div className='cb-quotes-amounts-chart'>
            <Doughnut
                data={{
                    labels: props.labels,
                    datasets: [{
                        data: props.amounts,
                        backgroundColor: props.colors
                    }]
                }}
                options={{
                    maintainAspectRatio: false,
                    cutout: '70%',
                    plugins: {
                        title: {
                            align: 'center',
                            display: true,
                            font: {
                                size: 20
                            },
                            text: 'Totales'
                        },
                        legend: {
                            position: 'left',
                            align: 'center',
                            labels: {
                                boxWidth: 20,
                                padding: 15,
                                font: {
                                    weight: 'bold',
                                    size: 14
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                size: 14
                            }
                        }
                    },
                    elements: {
                        center: {
                            text: `${props.amounts.reduce((a, b) => a + b, 0)}`,
                            color: '#2A4321'
                        }
                    }
                }}
                plugins={[{
                    beforeDraw: function(chart) {
                        if (chart.config.options.elements.center) {
                            // Get ctx from string
                            let ctx = chart.ctx

                            // Get options from the center object in options
                            let centerConfig = chart.config.options.elements.center
                            let fontFamily = centerConfig.fontFamily || "'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'"
                            let text = centerConfig.text
                            let color = centerConfig.color || '#222'
                            let maxFontSize = centerConfig.maxFontSize || 75
                            let sidePadding = centerConfig.sidePadding || 50
                            let innerRadius = chart._metasets[0].controller.innerRadius
                            let sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2)
                            
                            // Start with a base font of 30px
                            ctx.font = '30px ' + fontFamily

                            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                            let stringWidth = ctx.measureText(text).width
                            let elementWidth = (innerRadius * 2) - sidePaddingCalculated

                            // Find out how much the font can grow in width.
                            let widthRatio = elementWidth / stringWidth
                            let newFontSize = Math.floor(30 * widthRatio)
                            let elementHeight = (innerRadius * 2)

                            // Pick a new font size so it will not be larger than the height of label.
                            let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
                            let minFontSize = centerConfig.minFontSize
                            let lineHeight = centerConfig.lineHeight || 25
                            let wrapText = false

                            if (minFontSize === undefined) {
                                minFontSize = 20
                            }

                            if (minFontSize && fontSizeToUse < minFontSize) {
                                fontSizeToUse = minFontSize
                                wrapText = true
                            }

                            // Set font settings to draw it correctly.
                            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
                            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
                            ctx.textAlign = 'center'
                            ctx.textBaseline = 'middle'
                            ctx.font = fontSizeToUse + 'px ' + fontFamily
                            ctx.fillStyle = color

                            if (!wrapText) {
                                ctx.fillText(text, centerX, centerY)
                                return
                            }

                            let words = text.split(' ')
                            let line = ''
                            let lines = []

                            // Break words up into multiple lines if necessary
                            for (let n = 0; n < words.length; n++) {
                                let testLine = line + words[n] + ' '
                                let metrics = ctx.measureText(testLine)
                                let testWidth = metrics.width
                                if (testWidth > elementWidth && n > 0) {
                                    lines.push(line)
                                    line = words[n] + ' '
                                } else {
                                    line = testLine
                                }
                            }

                            // Move the center up depending on line height and number of lines
                            centerY -= (lines.length / 2) * lineHeight

                            for (let n = 0; n < lines.length; n++) {
                                ctx.fillText(lines[n], centerX, centerY)
                                centerY += lineHeight
                            }

                            //Draw text in center
                            ctx.fillText(line, centerX, centerY)
                        }
                    }
                }]}/>
        </div>
    )
}

export default QuotesAmountsChart