import React, { useEffect, useState } from 'react'
import { useUserGroups } from '../../contexts/Auth'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Card, Header, Icon, Input, List, Message } from 'semantic-ui-react'

import { quotesFilters, quoteStatus, quoteStatusNames } from '../../assets/constants/quote'
import * as utils from '../../assets/js/utils'
import { paths } from '../../config/routes'
import { useErrorState } from '../../contexts/ErrorHandling'

import FullPageLoader from '../../components/UI/FullPageLoader/FullPageLoader'
import InfoLabel from '../../components/UI/InfoLabel/InfoLabel'

import quotesService from '../../services/quotesService'

import './QuotesSearch.scss'

const QuotesSearch = () => {
    // Unused pagination. Still keeping it until behavior validation.
    const initialFilterConfig = {
        [quotesFilters.PAGE]: 1,
        [quotesFilters.PAGE_SIZE]: 10,
        [quotesFilters.SEARCH]: '',
        [quotesFilters.ORDER_BY]: '',
        [quotesFilters.DIRECTION]: ''
    }
    const history = useHistory()
    const { setErrorDialog } = useErrorState()
    const [foundQuotes, setFoundQuotes] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [filterConfig, setFilterConfig] = useState({...initialFilterConfig})
    const userGroups = useUserGroups()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setFilterConfig({
                ...filterConfig,
                [quotesFilters.SEARCH]: searchString
            })
        }
    }

    const searchQuotes = () => {
        setLoading(true)

        const params = {}

        for (let key in filterConfig) {
            const value = filterConfig[key]

            if (value) {
                params[key] = value
            }
        }

        //params['requester_id']= 41;

        quotesService.searchQuotes(params)
            .then(res => {
                setFoundQuotes(res.data)
                setLoading(false)
            })
            .catch(error => {
                const currentPage = filterConfig[quotesFilters.PAGE]

                if ((error?.response?.status === 404) && (currentPage > 1)) {
                    setFilterConfig({
                        ...filterConfig,
                        [quotesFilters.PAGE]: 1
                    })
                    return
                }

                setLoading(false)

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = 'Ha tenido lugar un error al cargar las cotizaciones. Por favor, inténtalo de nuevo más tarde.'
                }

                setErrorDialog({
                    title: 'Error cargando cotizaciones',
                    description: errorMessage,
                    onConfirm: () => history.push(paths.DASHBOARD)
                })
            })
    }

    useEffect(() => {
        if (filterConfig[quotesFilters.SEARCH].trim()) {
            searchQuotes()
        } else {
            setFoundQuotes(null)
        }
    }, [...Object.values(filterConfig)])

    return (
        <>
            <Helmet>
                <title>
                    Buscador de cotizaciones - CenterRisk
                </title>
            </Helmet>
            <div className='cb-quotes-search-wrapper'>
                <Header className='cb-page-title'>
                    Búsqueda global de cotizaciones
                    <Header.Subheader>
                        Introduce un texto de búsqueda para obtener cotizaciones con datos coincidentes
                    </Header.Subheader>
                </Header>
                <div className='cb-quotes-search'>
                    <header>
                        <Input
                            autoFocus
                            icon='search'
                            iconPosition='left'
                            placeholder='Buscar...'
                            className='cb-search-input'
                            value={searchString}
                            onChange={(event, data) => setSearchString(data.value)}
                            onKeyDown={handleKeyDown}/>
                        {
                            foundQuotes &&
                            <InfoLabel>
                                <strong>{foundQuotes.length}</strong>{' '}
                                RESULTADO{foundQuotes.length !== 1 ? 'S' : ''}{' '}
                                ENCONTRADO{foundQuotes.length !== 1 ? 'S' : ''}{' '}
                            </InfoLabel>
                        }
                    </header>
                    {
                        (!isLoading && foundQuotes && (foundQuotes.length > 0)) &&
                        <Card.Group stackable itemsPerRow={2} className='cb-found-quotes'>
                            {
                                foundQuotes.map((result, index) => (
                                    <Card
                                        link
                                        raised
                                        className='cb-found-quote'
                                        key={index}
                                        onClick={() => history.push(paths.QUOTE_MANAGEMENT.replace(':quoteId', result.id))}>
                                        <Card.Content>
                                            <Card.Header>{result.number} - {result.modality.short_name}</Card.Header>
                                            <Card.Meta>
                                                Fecha de {quoteStatus.closed().includes(result.status.code) ? 'cierre' : 'entrada'}: {' '}
                                                <i>{utils.datetimeToStr(result.display_date)}</i>
                                            </Card.Meta>
                                        </Card.Content>
                                        <Card.Content>
                                            <List>
                                                <List.Item>
                                                    <List.Icon name='user'/>
                                                    <List.Content>
                                                        <strong>Nombre de tomador: </strong>
                                                        {result.data.policyholderName.textual}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Icon name='building'/>
                                                    <List.Content>
                                                        <strong>NIF/CIF de tomador: </strong>
                                                        {result.data.policyholderTaxID.textual}
                                                    </List.Content>
                                                </List.Item>
                                                <List.Item>
                                                    <List.Icon name='briefcase'/>
                                                    <List.Content>
                                                        <strong>Solicitada por: </strong>
                                                        {result.requester.first_name}{' '}
                                                        {result.requester.last_name}{' '}
                                                        <i>({result.requester.brokerage.name})</i>
                                                    </List.Content>
                                                </List.Item>
                                            </List>
                                        </Card.Content>
                                        <Card.Content extra>
                                            Estado actual: {' '}
                                            <strong>{quoteStatusNames[result.status.code].singular.toLowerCase()}</strong>
                                        </Card.Content>
                                    </Card>
                                ))
                            }
                        </Card.Group>
                    }
                    {
                        (!isLoading && (foundQuotes === null)) &&
                        <Message info icon size='large' className='cb-info-message'>
                            <Icon name='info circle'/>
                            <Message.Content>
                                <Message.Header>
                                    ¿Qué quieres buscar?
                                </Message.Header>
                                El buscador tratará de encontrar coincidencias entre el texto introducido {' '}
                                y datos de cotizaciones ({userGroups.brokers ? 'NIF, Ramo' : 'tomador, correduría'}...).
                            </Message.Content>
                        </Message>
                    }
                    {
                        (!isLoading && foundQuotes && (foundQuotes.length === 0)) &&
                        <Message info icon size='large' className='cb-empty-message'>
                            <Icon name='info circle'/>
                            <Message.Content>
                                <Message.Header>
                                    Sin resultados
                                </Message.Header>
                                No se ha encontrado ninguna cotización cuyos datos coincidan {' '}
                                con el texto introducido.
                            </Message.Content>
                        </Message>
                    }
                </div>
                {
                    isLoading &&
                    <FullPageLoader text='Buscando cotizaciones...'/>
                }
            </div>
        </>
    )
}

export default QuotesSearch 
